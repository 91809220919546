import { ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { ReactNode } from 'react';
import { ReleaseStatusColorBarContainer, ReleaseStatusState } from './ReleaseStatusWithColorBar.styles';

interface ReleaseStatusColorBarProps {
  status: ReleaseStatusValue;
  useSecondaryColor?: boolean;
  children?: ReactNode;
}

export const ReleaseStatusWithColorBar: React.FC<ReleaseStatusColorBarProps> = ({ status, useSecondaryColor, children }) => (
  <ReleaseStatusColorBarContainer className={`mid-status-bar-${status}`}>
    <ReleaseStatusState className={useSecondaryColor ? 'mid-status-secondary' : 'mid-status-primary'}>
      {status}
    </ReleaseStatusState>
    {children}
  </ReleaseStatusColorBarContainer>
);
