import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import ReleasesSelection from 'components/ReleasesSelection/ReleasesSelection';
import useReleasesSelection from 'components/ReleasesSelection/useReleasesSelection';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ACCDocSelection,
  EMPTY_STATE_ILLUSTRATION_TYPES,
  FlexContainer,
  LocationContainer,
  MIDEmptyState,
  PreviewImage,
  PreviewInProgressMessageWrap,
  PreviewLoader,
  PreviewWrapper,
  ProductDetails,
  ProductDetailsWrapper,
  ProductFolderBrowser,
  useACCDocSelection,
  useBase64Thumbnail,
  useProductSelection,
} from 'mid-react-common';
import { isDCRfaOutput } from 'mid-utils';
import { useContext } from 'react';
import text from 'revit.text.json';
import { productFolderBrowserHeight } from 'screens/Screens.styles';
import DataContext from '../../context/Data/Data.context';

export const ProductSelectionScreen: React.FC = () => {
  const theme = useTheme();
  const { enableReleasesSelection } = useFlags();
  const { currentProductRelease, setCurrentProductRelease, nonObsoleteProductReleasesList } = useContext(DataContext);
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();
  const {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleNodeToggle,
    handleProductClick,
    products,
    productsLoading,
    productsError,
    selectedProduct,
    expandedFoldersIds,
  } = useProductSelection({
    selectedProjectId: selectedProject?.id,
    // reset the product selection once the project is changed
    onProjectChange: () => setCurrentProductRelease(undefined),
  });

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    currentProductRelease?.tenancyId,
    currentProductRelease?.thumbnail,
  );

  const { selectedRelease, selectedReleaseStatus, handleReleaseSelection } = useReleasesSelection({
    projectId: selectedProject?.id,
    selectedProduct,
  });

  const rfaOutput = currentProductRelease?.outputs.find(isDCRfaOutput);
  const isProductReleaseLoading = selectedProduct && !currentProductRelease;

  return (
    <LocationContainer>
      <FlexContainer gap={theme.var.marginBase}>
        <Box>
          <ACCDocSelection
            maxHeight={productFolderBrowserHeight}
            accounts={accounts || []}
            accountsLoading={accountsLoading}
            projects={projects || []}
            projectsLoading={projectsLoading}
            selectedAccount={selectedAccount}
            selectedProject={selectedProject}
            projectsDisabled={projectsDisabled}
            handleSelectAccount={handleSelectAccount}
            handleSelectProject={handleSelectProject}
          />
          <ProductFolderBrowser
            expandedFoldersIds={expandedFoldersIds}
            projectId={selectedProject?.id || ''}
            rootFolders={rootFoldersTreeItems}
            rootFoldersLoading={rootFoldersLoading}
            rootFoldersError={rootFoldersError}
            selectedFolderTreeElement={selectedFolderTreeElement}
            products={products}
            productsLoading={productsLoading}
            productsError={productsError}
            selectedProductId={selectedProduct?.contentId}
            onFolderClick={handleFolderClick}
            onNodeToggle={handleNodeToggle}
            onProductClick={handleProductClick}
            maxHeight={productFolderBrowserHeight}
          />
          {enableReleasesSelection && selectedProduct?.contentId && (
            <ReleasesSelection
              productReleases={nonObsoleteProductReleasesList}
              selectedRelease={selectedRelease}
              handleReleaseSelection={handleReleaseSelection}
            />
          )}
        </Box>
        <ProductDetailsWrapper>
          {currentProductRelease?.contentId ? (
            <div>
              <PreviewWrapper>
                {thumbnailLoading ? (
                  <PreviewLoader>
                    <CircularProgress
                      size={theme.var.circularProgressSize}
                      thickness={theme.var.circularProgressThickness}
                    />
                    <PreviewInProgressMessageWrap>{text.loadingThumbnail}</PreviewInProgressMessageWrap>
                  </PreviewLoader>
                ) : thumbnailInBase64 ? (
                  <PreviewImage src={`data:image/jpeg;base64,${thumbnailInBase64}`} alt={text.previewImageAltText} />
                ) : (
                  <MIDEmptyState
                    illustrationType={
                      thumbnailError
                        ? EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR
                        : EMPTY_STATE_ILLUSTRATION_TYPES.PAGES_PHOTO
                    }
                    title={thumbnailError ? text.previewNotAvailable : ''}
                  />
                )}
              </PreviewWrapper>
              <ProductDetails
                title={currentProductRelease.name || ''}
                productCategory={rfaOutput?.options?.category || ''}
                productFamily={rfaOutput?.options?.family || ''}
                representations={rfaOutput?.options?.modelStates || []}
                releaseStatus={selectedReleaseStatus}
                releaseNumber={selectedRelease}
              />
            </div>
          ) : (
            <>
              {isProductReleaseLoading && (
                <PreviewLoader>
                  <CircularProgress />
                </PreviewLoader>
              )}
            </>
          )}
        </ProductDetailsWrapper>
      </FlexContainer>
    </LocationContainer>
  );
};
