import React from 'react';
import text from '../../mid-react-common.text.json';
import { StyledErrorFallback } from './ErrorBoundary.styles';

interface ErrorFallbackProps {
  error: Error;
  clearError: () => void;
  secondaryMessage?: string;
  resetButtonText?: string;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  clearError,
  secondaryMessage = text.errorBoundaryText,
  resetButtonText = text.errorBoundaryButtonText,
}) => (
  <StyledErrorFallback role="alert">
    <h1>{text.errorBoundaryTitle}</h1>
    <p className="error-message">{error.message}</p>
    <p>{secondaryMessage}</p>
    <button data-testid="button-reset-app-state" onClick={clearError}>
      {resetButtonText}
    </button>
  </StyledErrorFallback>
);

export default ErrorFallback;
