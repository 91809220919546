import { fileToBase64String, urlContentToBase64String } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCancellablePromise } from '../misc';
import { DcApiService, InversifyTypes, inversifyContainer } from 'mid-api-services';

export interface UseBase64ThumbnailState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
}

type CacheKey = string;
type CachedBase64Thumbnail = string;
export const thumbnailsCache = new Map<CacheKey, CachedBase64Thumbnail>();

export const useBase64Thumbnail = (tenancyId?: string, thumbnailObjectKey?: string): UseBase64ThumbnailState => {
  const [thumbnailInBase64, setThumbnailInBase64] = useState<string | undefined>();
  const [thumbnailLoading, setThumbnailLoading] = useState<boolean>(false);
  const [thumbnailError, setThumbnailError] = useState<string | undefined>();
  const prevThumbnailObjectKey = useRef<string>();
  const cancellablePromise = useCancellablePromise();

  const getThumbnailInBase64 = async (tenancyId: string, thumbnailObjectKey: string): Promise<string> => {
    let thumbnailInBase64;
    if (thumbnailObjectKey.indexOf(':\\') > 0 || thumbnailObjectKey.indexOf(':/') > 0) {
      // string is local file path
      thumbnailInBase64 = await fileToBase64String(thumbnailObjectKey);
    } else {
      // Retrieve the download url
      const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
      const downloadURLPayload = { objectKey: thumbnailObjectKey };
      const downloadURLResponse = await dcApiService.downloadURL(tenancyId, downloadURLPayload);
      const downloadUrl = downloadURLResponse.signedUrl;

      // Transform the file into base64
      thumbnailInBase64 = await urlContentToBase64String(downloadUrl);
    }

    return thumbnailInBase64.base64;
  };

  const fetchThumbnail = useCallback(async (tenancyId: string, thumbnailObjectKey: string) => {
    const thumbnailCacheId = tenancyId + thumbnailObjectKey;

    if (thumbnailsCache.get(thumbnailCacheId)) {
      prevThumbnailObjectKey.current = thumbnailObjectKey;
      setThumbnailInBase64(thumbnailsCache.get(thumbnailCacheId));
      return;
    }

    try {
      setThumbnailLoading(true);
      const thumbnailInBase64 = await getThumbnailInBase64(tenancyId, thumbnailObjectKey);
      prevThumbnailObjectKey.current = thumbnailObjectKey;
      setThumbnailInBase64(thumbnailInBase64);
      thumbnailsCache.set(thumbnailCacheId, thumbnailInBase64);
    } catch (err) {
      setThumbnailInBase64(undefined);
      setThumbnailError(err as string);

      logError(err);
    } finally {
      setThumbnailLoading(false);
    }
  }, []);

  useEffect(() => {
    if (tenancyId && thumbnailObjectKey && prevThumbnailObjectKey.current !== thumbnailObjectKey) {
      cancellablePromise(fetchThumbnail(tenancyId, thumbnailObjectKey));
    }
    if (thumbnailInBase64 && (!tenancyId || !thumbnailObjectKey)) {
      prevThumbnailObjectKey.current = undefined;
      setThumbnailInBase64(undefined);
    }
  }, [fetchThumbnail, tenancyId, thumbnailInBase64, thumbnailObjectKey, cancellablePromise]);

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
  };
};
