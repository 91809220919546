import { DCInput } from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties } from 'mid-react-common';

export enum ConfigurableProductActionTypes {
  INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD = 'INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD',
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_ALL_INPUTS = 'UPDATE_ALL_INPUTS',
}

export type ConfigurableProductActions =
  | {
      type: ConfigurableProductActionTypes.UPDATE_INPUT;
      payload: DCInput;
    }
  | {
      type: ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD;
      payload: ConfigurableProductProperties;
    }
  | {
      type: ConfigurableProductActionTypes.UPDATE_ALL_INPUTS;
      payload: DCInput[];
    };
