import { useEffect, useReducer, useState, useMemo } from 'react';
import { ConfigurableProductProperties, StateSetter } from 'mid-react-common';
import {
  VariantFormState,
  VariantFormStates,
  initialConfigurableProductPropertiesMap,
  initializeConfigurableProductProperties,
} from 'mid-react-common/revit-components';
import { ConfigurableProductActionTypes, configurableProductReducer } from 'mid-react-common/revit-components';
import { ProductRelease, Variant, DCInput, ReleaseStatus } from '@adsk/offsite-dc-sdk';
export interface DataStore {
  currentProductRelease: ProductRelease | undefined;
  setCurrentProductRelease: StateSetter<ProductRelease | undefined>;
  configurableProductProperties: ConfigurableProductProperties;
  updateConfigurableProductInput: (inputToUpdate: DCInput) => void;
  resetConfigurableProductProperties: () => void;
  updateConfigurableProductInputs: (inputToUpdate: DCInput[]) => void;
  selectedCachedVariant: Variant | null;
  setSelectedCachedVariant: StateSetter<Variant | null>;
  variantFormState: VariantFormStates;
  setVariantFormState: StateSetter<VariantFormStates>;
  selectedVariantThumbnailVisible: boolean;
  setSelectedVariantThumbnailVisible: StateSetter<boolean>;
  reFetchCachedVariants: boolean;
  setReFetchCachedVariants: StateSetter<boolean>;
  cachedVariantsList: Variant[];
  setCachedVariantsList: StateSetter<Variant[]>;
  selectedRepresentation: string | undefined;
  setSelectedRepresentation: StateSetter<string | undefined>;
  productReleasesListLength: number | undefined;
  setProductReleasesList: StateSetter<ProductRelease[] | undefined>;
  nonObsoleteProductReleasesList: ProductRelease[] | undefined;
  postedVariantIdsList: string[];
  setPostedVariantIdsList: StateSetter<string[]>;
}

export enum ProductCustomizationTabs {
  TAB_NEW,
  TAB_EXISTING,
}

export const useStore = (): DataStore => {
  const [currentProductRelease, setCurrentProductRelease] = useState<ProductRelease | undefined>();
  const [configurableProductPropertiesMap, dispatchConfigurableProductUpdateAction] = useReducer(
    configurableProductReducer,
    initialConfigurableProductPropertiesMap,
  );

  const [selectedCachedVariant, setSelectedCachedVariant] = useState<Variant | null>(null);
  const [variantFormState, setVariantFormState] = useState<VariantFormStates>(VariantFormState.DEFAULT_VARIANT);

  const [selectedVariantThumbnailVisible, setSelectedVariantThumbnailVisible] = useState<boolean>(true);

  const [reFetchCachedVariants, setReFetchCachedVariants] = useState(true);
  const [cachedVariantsList, setCachedVariantsList] = useState<Variant[]>([]);

  const [selectedRepresentation, setSelectedRepresentation] = useState<string | undefined>();

  const [productReleasesList, setProductReleasesList] = useState<ProductRelease[] | undefined>();
  const nonObsoleteProductReleasesList = useMemo(
    () => productReleasesList?.filter((release) => release.status !== ReleaseStatus.OBSOLETE),
    [productReleasesList],
  );
  const [postedVariantIdsList, setPostedVariantIdsList] = useState<string[]>([]);

  useEffect(() => {
    if (currentProductRelease) {
      initializeConfigurableProductProperties(
        currentProductRelease,
        dispatchConfigurableProductUpdateAction,
        setSelectedRepresentation,
      );
    }
  }, [currentProductRelease]);

  const updateConfigurableProductInput = (inputToUpdate: DCInput) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_INPUT,
      payload: inputToUpdate,
    });
  };

  const updateConfigurableProductInputs = (updatedInputs: DCInput[]) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_ALL_INPUTS,
      payload: updatedInputs,
    });
  };

  // TODO: if/when we integrate outputs changes, implements a setConfigurableProductOutput method
  const resetConfigurableProductProperties = () => {
    if (currentProductRelease) {
      initializeConfigurableProductProperties(
        currentProductRelease,
        dispatchConfigurableProductUpdateAction,
        setSelectedRepresentation,
      );
    }
  };

  return {
    currentProductRelease,
    setCurrentProductRelease,
    configurableProductProperties: {
      inputs: [...configurableProductPropertiesMap.inputs.values()],
      outputs: configurableProductPropertiesMap.outputs,
    },
    selectedCachedVariant,
    setSelectedCachedVariant,
    variantFormState,
    setVariantFormState,
    selectedVariantThumbnailVisible,
    setSelectedVariantThumbnailVisible,
    updateConfigurableProductInput,
    resetConfigurableProductProperties,
    updateConfigurableProductInputs,
    reFetchCachedVariants,
    setReFetchCachedVariants,
    cachedVariantsList,
    setCachedVariantsList,
    selectedRepresentation,
    setSelectedRepresentation,
    productReleasesListLength: productReleasesList?.length,
    setProductReleasesList,
    nonObsoleteProductReleasesList,
    postedVariantIdsList,
    setPostedVariantIdsList,
  };
};
