import React from 'react';
import { DeployEnvironment } from './styles';
import { getEnvironmentLabel } from './useEnvironmentInfo';

export interface EnvironmentInfoStateProps {
  hostname: string;
}

export const EnvironmentInfo: React.FC<EnvironmentInfoStateProps> = ({ hostname }): JSX.Element => {
  const env = getEnvironmentLabel(hostname);
  return <>{env && <DeployEnvironment>{env}</DeployEnvironment>}</>;
};
