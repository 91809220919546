import { FolderPermissionAction } from 'mid-types';
import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  projectId: string;
  folderUrns: string[];
  permissionActions: FolderPermissionAction[];
};

export class CheckPermissionError extends CustomError {
  errorCode = ErrorCode.CheckPermissionError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
