// Global Layout
const midwMainHeader = 48;
const midwNavigation = 45;

// Models Page
const modelsFolderContentContainerMinWidth = 500;

// SettingsPanel
const settingsPanelWidth = 350;
const settingsPanelHeaderHeight = 50;
const settingsPanelOffset = 220;
const settingsPanelFontSize = 14;

// DetailsSidebar
const detailsSidebarMinWidth = 350;

// Custom Accrordion
const customAccordionContentHeader = 34;

// ReviewPanel Datagrid
const reviewPanelDataGridRowHeight = 40;
const reviewPanelDataGridHeaderHeight = 45;

// LogPreview Table
const logPreviewNumbersWidth = 45;
const logsWrapperMinWidth = 500;

// Instance Panel
const productSelectDropdownWidth = 200;
const instanceSidebarWidth = 500;
const instanceResultNameWidth = 150;
const instanceResultInfoWidth = 300;

// Releases Tab
const productsSearchFieldHeight = 55;
const releasesControlsHeight = 66.5;
const releaseDetailsFolderIconWidth = 24;

// Release status
const releaseStatusColorBarWidth = 5;
const releaseStatusColorBarBorderRadius = 2;
const releaseStatusMinWidth = 200;

// Licensing Page
const licensingPageHeaderHeight = 150;

// Account Project Selector
const accountProjectSelectorMinWidth = 300;
const headerPanelAccountProjectSelectorMaxWidth = 500;

// Folders Tree
const folderNodeMargin = 8;
const newFolderInputHeight = 35;
const newFolderInputMargin = 4;
const newFolderInputRadius = 4;

// Output project selector
const projectIconSize = 16;
const outputProjectDropdownWidth = 400;
const outputProjectDropdownMaxViewHeight = '60vh';
const outputAccountProjectGap = 8;

export const midwConstants = {
  settingsPanelFontSize,
  settingsPanelHeaderHeight,
  settingsPanelOffset,
  settingsPanelWidth,
  modelsFolderContentContainerMinWidth,
  instanceSidebarWidth,
  instanceResultNameWidth,
  instanceResultInfoWidth,
  logPreviewNumbersWidth,
  logsWrapperMinWidth,
  productSelectDropdownWidth,
  reviewPanelDataGridHeaderHeight,
  productsSearchFieldHeight,
  reviewPanelDataGridRowHeight,
  releasesControlsHeight,
  detailsSidebarMinWidth,
  customAccordionContentHeader,
  midwMainHeader,
  releaseDetailsFolderIconWidth,
  releaseStatusColorBarWidth,
  releaseStatusColorBarBorderRadius,
  releaseStatusMinWidth,
  midwTopBars: midwMainHeader + midwNavigation,
  licensingPageHeaderHeight,
  accountProjectSelectorMinWidth,
  headerPanelAccountProjectSelectorMaxWidth,
  folderNodeMargin,
  newFolderInputHeight,
  newFolderInputMargin,
  newFolderInputRadius,
  projectIconSize,
  outputProjectDropdownWidth,
  outputProjectDropdownMaxViewHeight,
  outputAccountProjectGap,
};
