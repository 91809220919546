import React, { ReactNode } from 'react';
import { AddinWrapper } from './AddinContainer.styles';

interface AddinContainerProps {
  children: ReactNode;
}

const AddinContainer: React.FC<AddinContainerProps> = ({ children }) => <AddinWrapper>{children}</AddinWrapper>;

export default AddinContainer;
