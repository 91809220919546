import { ErrorCode } from './errorCode';
import { CustomError } from './customError';
import { ProductRelease } from '@adsk/offsite-dc-sdk';

type CauseType = {
  currentProductRelease?: ProductRelease;
};

export class ProductReleaseError extends CustomError {
  errorCode = ErrorCode.ProductReleaseError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
