export const emptyStateTestIds = {
  midEmptyStateContainer: 'mid-empty-state-container',
  midEmptyStateSvg: 'mid-empty-state-svg',
};

export const productCustomizationTestIds = {
  productCustomizationFormLoadingSpinner: 'product-customization-form-loading-spinner',
  productCustomizationFormLoadingSpinnerOverlay: 'product-customization-form-loading-spinner-overlay',
  productCustomizationForm: 'product-customization-form',
  productCustomizationFormWrapper: 'product-customization-form-wrapper',
  formRowItemTestIdPrefix: 'form-row-item-',
  insertButton: 'insert-button',
  customizeButton: 'customize-button',
  productCustomizationFormHeader: 'product-customization-form-header',
  productCustomizationFormGroupContent: 'product-customization-form-group-content',
  productCustomizationFormGroup: 'product-customization-form-group',
};

export const numericParameterCustomizationTestIds = {
  numericInputTooltipWrapper: 'numeric-input-tooltip-wrapper',
};

export const notificationTestIds = {
  notificationSnackbar: 'notification-snackbar',
};

export const summaryTableTestIds = {
  previewTable: 'preview-table',
};

export const confirmationDialogTestIds = {
  confirmButton: 'confirmation-dialog-confirm-button',
  secondaryConfirmButton: 'confirmation-dialog-secondary-confirm-button',
  cancelButton: 'confirmation-dialog-cancel-button',
};

export const productFolderBrowser = {
  productCell: 'product-cell',
};

export const aboutInfoMenu = {
  aboutInfoButton: 'about-info-button',
  licensingPageLink: 'licensing-page-link',
  inventorInstallersPageLink: 'inventor-installers-page-link',
  revitInstallersPageLink: 'revit-installers-page-link',
  termsAndConditionsPageLink: 'terms-and-conditions-page-link',
  informedDesignHelpPageLink: 'informed-design-help-page-link',
};

export const nameAndRelease = {
  releaseName: 'release-name',
  releasesAndStatus: 'releases-and-status',
};
