import { BatchFetchProductsResult } from 'mid-types';
import { DCInputNumericType, DCInputTextType, DCRules, DynamicContent, InputType } from '@adsk/offsite-dc-sdk';
import { getContentFromObjectKey } from './cloudStorage';
import { inputsRulesToTemplateRules } from './tools';
import { InputRule } from '../interfaces/productDefinitions';
import { DcApiService, InversifyTypes, inversifyContainer } from 'mid-api-services';
import { processAllSettledResults } from 'mid-utils';

const migrateProductMultiValueInputs = (product: DynamicContent) => {
  const compatibleProduct: DynamicContent = {
    ...product,
    inputs: product.inputs.map((input) => {
      if (input.type === InputType.MULTI_VALUE_TEXT) {
        const textInput: DCInputTextType = {
          type: InputType.TEXT,
          name: input.name,
          label: input.label,
          value: input.value!,
          values: input.values,
          unit: input.unit,
          visible: input.visible,
          allowCustomValue: true,
          readOnly: input.readOnly,
        };
        return textInput;
      }

      if (input.type === InputType.MULTI_VALUE_NUMERIC) {
        const numericInput: DCInputNumericType = {
          type: InputType.NUMERIC,
          name: input.name,
          label: input.label,
          value: input.value!,
          values: input.values,
          min: input.min,
          max: input.max,
          unit: input.unit,
          visible: input.visible,
          allowCustomValue: true,
          readOnly: input.readOnly,
          onChange: input.onChange,
        };
        return numericInput;
      }

      return input;
    }),
  };
  return compatibleProduct;
};

export interface GetAllProductsInProjectArgs {
  projectId: string;
  enableMultiValuesBackwardsCompatibility: boolean;
}

export const getAllProductsInProject = async (
  projectId: string,
  enableMultiValuesBackwardsCompatibility: boolean,
): Promise<DynamicContent[]> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const productsList: DynamicContent[] = await dcApiService.getProductsList(projectId);

  if (enableMultiValuesBackwardsCompatibility) {
    return productsList.map(migrateProductMultiValueInputs);
  }
  return productsList;
};

export const getProduct = async (
  projectId: string,
  productId: string,
  enableMultiValuesBackwardsCompatibility: boolean,
): Promise<DynamicContent> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const product: DynamicContent = await dcApiService.getProduct(projectId, productId);

  if (enableMultiValuesBackwardsCompatibility) {
    const compatibleProduct: DynamicContent = migrateProductMultiValueInputs(product);
    return compatibleProduct;
  }
  return product;
};

export const getAllProductsForProjects = async (
  projectIds: string[],
  enableMultiValuesBackwardsCompatibility: boolean,
): Promise<BatchFetchProductsResult | null> => {
  if (projectIds.length === 0) {
    return null;
  }

  const resultsPromise = projectIds.map(
    async (projectId): Promise<DynamicContent[]> =>
      await getAllProductsInProject(projectId, enableMultiValuesBackwardsCompatibility),
  );
  const allSettledResult = await Promise.allSettled<DynamicContent[]>(resultsPromise);
  const processedAllSettledResult = processAllSettledResults(allSettledResult);
  return processedAllSettledResult;
};

export const checkAndDownloadProductRulesFromKey = async (
  tenancyId: string,
  rulesKey: string | undefined,
): Promise<DCRules | undefined> => {
  if (rulesKey) {
    const inputRules = await getContentFromObjectKey<InputRule[]>(tenancyId, rulesKey);
    if (inputRules && inputRules.length > 0) {
      return inputsRulesToTemplateRules(inputRules);
    }
  }
};
