import { useCallback, useEffect, useState } from 'react';

import { getAssemblyVersion, getDesktopApplicationVersionNumber } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { MidWebAppMoniker } from '../../global/constants';

interface UseAboutInfoMenuState {
  anchorElement: HTMLElement | null;
  isVersionInfoDialogOpen: boolean;
  addinVersion?: string;
  desktopApplicationVersion?: string;
  handleInfoIconClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  handleVersionInfoMenuItemClick: () => void;
  handleVersionInfoDialogClose: () => void;
}

export const useAboutInfoMenu = (webComponentVersion: string): UseAboutInfoMenuState => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isVersionInfoDialogOpen, setIsVersionInfoDialogOpen] = useState(false);

  const [addinVersion, setAddinVersion] = useState<string | undefined>();
  const [desktopApplicationVersion, setDesktopApplicationVersion] = useState<string | undefined>();

  const handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleVersionInfoMenuItemClick = () => {
    setIsVersionInfoDialogOpen(true);
    setAnchorElement(null);
  };

  const handleVersionInfoDialogClose = () => {
    setIsVersionInfoDialogOpen(false);
  };

  const setAddinVersionAndDesktopApplicationVersion = useCallback(async () => {
    const addinVersion = await getAssemblyVersion();
    const desktopVersion = await getDesktopApplicationVersionNumber();

    setAddinVersion(addinVersion);
    setDesktopApplicationVersion(desktopVersion);
  }, [setAddinVersion, setDesktopApplicationVersion]);

  useEffect(() => {
    try {
      if (!webComponentVersion.includes(MidWebAppMoniker)) {
        setAddinVersionAndDesktopApplicationVersion();
      }
    } catch (error) {
      logError(error);
    }
  }, [webComponentVersion, setAddinVersionAndDesktopApplicationVersion]);

  return {
    anchorElement,
    isVersionInfoDialogOpen,
    addinVersion,
    desktopApplicationVersion,
    handleInfoIconClick,
    handleMenuClose,
    handleVersionInfoMenuItemClick,
    handleVersionInfoDialogClose,
  };
};
