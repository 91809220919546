import { DefaultBodyType, MockedResponse, PathParams, ResponseComposition, RestContext, RestRequest } from 'msw';
import { mockVariantStatusIterator } from './data/dataGeneratorUtils';
import { mockAccount, mockAccount2 } from './data/mockAccounts';
import { mockFolder1, mockFolder2, mockNewFolder, mockFolderTree1, getSubFolderMockResponse } from './data/mockFolders';
import {
  mockFormRules,
  mockJavascriptRules1,
  mockPostProductResponse,
  mockProducts,
  mockProductWithRules3,
  mockProjectIdWithError,
  ruleObjectKeyWError,
  rulesObjectKey,
} from './data/mockProducts';
import { mockProject, mockProject2 } from './data/mockProjects';
import { mockUserProfile } from './data/mockUserProfile';
import { mockUserAnalytics } from './data/mockUserAnalytics';
import {
  mockGetVariantLogsResponse,
  mockGetVariantResponse,
  mockPostVariantResponse,
  mockVariant,
  mockVariant1ForMockProductWithRules3,
  mockVariant2,
  mockVariant3,
} from './data/mockVariants';
import { mockBIM360Document, mockProcessingBIM360Document } from './data/mockBIM360Documents';
import { mockManifest } from './data/mockManifest';
import { mockProductUserConfiguration } from './data/mockUserConfigurations';
import { metadataJSON } from './data/mockMetadata';
import { mockProductReleasesList } from './data/mockProductReleases';
import { OutputStatus, Variant } from '@adsk/offsite-dc-sdk';

const RESPONSE_DELAY = 500;
type MockHandlerResponseType = MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>>;

export const getAccountsHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          res(
            ctx.status(200),
            ctx.json({
              results: [mockAccount, mockAccount2],
              pagination: {
                limit: 100,
                offset: 0,
                totalResults: 2,
              },
            }),
          ),
        ),
      RESPONSE_DELAY,
    );
  });

export const getProjectsHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          res(
            ctx.status(200),
            ctx.json({
              results: [mockProject, mockProject2],
              pagination: {
                limit: 100,
                offset: 0,
                totalResults: 2,
              },
            }),
          ),
        ),
      RESPONSE_DELAY,
    );
  });

export const getProjectByIdHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockProject))), RESPONSE_DELAY);
  });

export const getFoldersHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json({ folders: [mockFolder1, mockFolder2] }))), RESPONSE_DELAY);
  });

export const createNewfolder = async (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): Promise<MockedResponse<DefaultBodyType>> => {
  const body = await req.json();
  const { parent_folder_urn, title } = body;
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          res(
            ctx.status(200),
            ctx.json({
              ...mockNewFolder,
              urn: `${mockNewFolder.urn}-${parent_folder_urn}-${title}`,
              parent_urn: parent_folder_urn,
              title,
            }),
          ),
        ),
      RESPONSE_DELAY,
    );
  });
};

export const getSubFoldersHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const result = getSubFolderMockResponse(req.params.folderUrn as string);
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json({ folders: result ? result : [] }))), RESPONSE_DELAY);
  });
};

export const getAllProductsInProjectHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const projectId = req.params.projectId as string;
  if (projectId === mockProjectIdWithError) {
    return res(ctx.status(500));
  }
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json({ results: mockProducts, pagination: {} }))), RESPONSE_DELAY);
  });
};

export const getProductHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockProductWithRules3))), RESPONSE_DELAY);
  });

export const getProductReleasesHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>> =>
  new Promise((resolve) => {
    setTimeout(
      () => resolve(res(ctx.status(200), ctx.json({ results: mockProductReleasesList, pagination: {} }))),
      RESPONSE_DELAY,
    );
  });

export const bypassRequest = (): void => undefined;

export const getVariantHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const projectId: string = req.params.projectId as string;
  const productId: string = req.params.productId as string;
  const variantId: string = req.params.variantId as string;

  const outputStatus = mockVariantStatusIterator.next().value;
  const getVariantResponse: Variant = {
    ...mockPostVariantResponse,
    outputs: mockGetVariantResponse.outputs.map((output: any) => ({
      ...output,
      status: outputStatus,
    })),
    // so we mimic returning a new thumbnail value on every getVariant
    // where all the outputs are successful
    thumbnail: outputStatus === OutputStatus.SUCCESS ? `thumnail-${Math.random()}` : '',
    variantId,
    tenancyId: projectId,
    contentId: productId,
  };
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(getVariantResponse))), RESPONSE_DELAY);
  });
};

export const postVariantHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const projectId: string = req.params.projectId as string;
  const productId: string = req.params.productId as string;
  const postVariantResponse: Variant = {
    ...mockPostVariantResponse,
    thumbnail: '',
    tenancyId: projectId,
    contentId: productId,
  };
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(postVariantResponse))), RESPONSE_DELAY);
  });
};

export const getDownloadUrlHandler = async (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): Promise<MockedResponse<DefaultBodyType>> => {
  const { objectKey } = await req.json();

  let mockDownloadUrl = 'https://s3.aws.com/path/to/thumbnail.png';
  if (objectKey === rulesObjectKey) {
    mockDownloadUrl = 'https://s3.aws.com/path/to/rules.json';
  }
  if (objectKey === ruleObjectKeyWError) {
    mockDownloadUrl = 'https://s3.aws.com/path/to/rulesWithError.json';
  }
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json({ signedUrl: mockDownloadUrl }))), RESPONSE_DELAY);
  });
};

export const postUploadUrls = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const response = {
    urls: ['https://s3.aws.com/path/to/thumbnail.png'],
    uploadKey: 'mock upload key',
    uploadExpiration: 123,
    urlExpiration: 124,
    objectKey: 'mock-object-key',
  };
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(response))), RESPONSE_DELAY);
  });
};

export const postCompleteUpload = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const response = {
    contentType: 'mock content type',
    fileName: 'mock file name',
    fileSize: 123,
    objectKey: 'mock object key',
  };
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(response))), RESPONSE_DELAY);
  });
};
export const postProduct = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockPostProductResponse))), RESPONSE_DELAY);
  });

export const putUploadUrl = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(null))), RESPONSE_DELAY);
  });

export const deleteProductTemplateHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const productId = req.params.productId as string;
  if (productId === 'not-existed') {
    return res(ctx.status(500));
  }
  return new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(204))), RESPONSE_DELAY);
  });
};

export const getVariantLog = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockGetVariantLogsResponse))), RESPONSE_DELAY);
  });

export const getRulesObject = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>> =>
  new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          res(
            ctx.status(200),
            ctx.json([
              {
                key: 'currentRule',
                code: mockJavascriptRules1,
              },
              {
                key: 'formRules',
                code: JSON.stringify(mockFormRules),
              },
            ]),
          ),
        ),
      RESPONSE_DELAY,
    );
  });

export const getRulesObjectWithError = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(500))), RESPONSE_DELAY);
  });

export const getUserProfile = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockUserProfile))), 200);
  });

export const getFolderTreeHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => res(ctx.status(200), ctx.json({ folder_tree: [mockFolderTree1] }));

export const getAnalyticsId = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockUserAnalytics))), 200);
  });

export const getFolderContentHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType =>
  res(
    ctx.status(200),
    ctx.json({
      documents: [mockBIM360Document, mockProcessingBIM360Document],
    }),
  );

export const getManifestHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => res(ctx.status(200), ctx.json(mockManifest));

export const getThumbnailHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => res(ctx.status(200), ctx.json('mock blob data'));

export const getVariantsByProductHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => {
  const projectId: string = req.params.projectId as string;
  const productId: string = req.params.productId as string;

  if (
    projectId === mockVariant1ForMockProductWithRules3.tenancyId &&
    productId === mockVariant1ForMockProductWithRules3.contentId
  ) {
    return res(ctx.status(200), ctx.json({ pagination: {}, results: [mockVariant1ForMockProductWithRules3] }));
  }

  if (projectId === mockVariant.tenancyId && productId === mockVariant.contentId) {
    return res(ctx.status(200), ctx.json({ pagination: {}, results: [mockVariant] }));
  }
  if (projectId === mockVariant2.tenancyId && productId === mockVariant2.contentId) {
    return res(ctx.status(200), ctx.json({ pagination: {}, results: [mockVariant2] }));
  }
  if (projectId === mockVariant3.tenancyId && productId === mockVariant3.contentId) {
    return res(ctx.status(200), ctx.json({ pagination: {}, results: [mockVariant3] }));
  }

  return res(ctx.status(500));
};

export const getVariantOutputsHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => res(ctx.status(200), ctx.json(mockVariant.outputs));

export const getMSIMetadata = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockHandlerResponseType => res(ctx.status(200), ctx.json(metadataJSON));

export const putProductUserConfigurationHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json(mockProductUserConfiguration))), RESPONSE_DELAY);
  });

export const amplitudeHandler = (
  req: RestRequest<any, PathParams>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
): MockedResponse<DefaultBodyType> | Promise<MockedResponse<DefaultBodyType>> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(res(ctx.status(200), ctx.json({}))), RESPONSE_DELAY);
  });
