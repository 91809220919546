import CssBaseline from '@mui/material/CssBaseline';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  AddinContainer,
  AppLoadingProgress,
  ConfirmationModal,
  EnvironmentInfo,
  ErrorBoundary,
  ErrorFallback,
  ModalContext,
  NotificationsProvider,
  RevitWebComponentMoniker,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  darkTheme,
  lightTheme,
  useModalStore,
  useNotificationStore,
} from 'mid-react-common';
import React, { useEffect, useState } from 'react';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import DataContext from './context/Data/Data.context';
import { useStore } from './context/Data/dataStore';
import NavigationContext from './context/Navigation/Navigation.context';
import { useNavigationStore } from './context/Navigation/navigationStore';
import { getAuthTokenAndEnv, browserApiService } from 'mid-addin-lib';
import Divider from '@mui/material/Divider';
import { ampli } from './ampli';
import { ServiceConfigMap, ServiceTypes, handleAmplitudeIdentify, setAmplitudeGroup } from 'mid-utils';
import {
  inversifyContainer,
  InversifyTypes,
  registerDcApiBaseURL,
  registerEnv,
  registerAuthHandler,
  registerForgeApiBaseURL,
} from 'mid-api-services';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  moniker: RevitWebComponentMoniker,
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  appVersion: import.meta.env.VITE_RVTW_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode, enableDarkModeSwitch } = useFlags();
  const navigationStore = useNavigationStore();
  const dataStore = useStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();
  const [dark, setDark] = useState<boolean>(false);
  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);

  useEffect(() => {
    const registerDependency = async () => {
      const { token, env } = await getAuthTokenAndEnv();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][env];

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerDcApiBaseURL(dcApiBaseURL);
      }

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => Promise.resolve(token));
      }

      if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
        registerForgeApiBaseURL(forgeApiBaseUrl.api);
      }

      setApiServicesInitialized(true);
    };
    registerDependency();
  }, []);

  const handleResetAppState = (): void => window.location.reload();

  const handleThemeChange = () => {
    setDark((state) => !state);
  };

  const appTree = (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal isAddin />
        <AddinContainer>
          <NotificationsProvider store={notificationStore}>
            {apiServicesInitialized ? (
              <UserAnalyticsProvider
                handleAmplitudeIdentify={handleAmplitudeIdentify.bind(ampli)}
                setAmplitudeGroup={setAmplitudeGroup.bind(ampli)}
                webComponentMoniker={RevitWebComponentMoniker}
              >
                {enableMaintenanceMode ? (
                  <UnderMaintenance />
                ) : (
                  <NavigationContext.Provider value={navigationStore}>
                    <DataContext.Provider value={dataStore}>
                      {enableDarkModeSwitch && (
                        <Switch
                          onChange={handleThemeChange}
                          sx={{ position: 'absolute', top: 0, left: '50%', zIndex: 1000 }}
                        />
                      )}
                      <Header />
                      <Divider />
                      <EnvironmentInfo hostname={window.location.host} />
                      <ScreenManager />
                    </DataContext.Provider>
                  </NavigationContext.Provider>
                )}
              </UserAnalyticsProvider>
            ) : (
              <AppLoadingProgress />
            )}
          </NotificationsProvider>
        </AddinContainer>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
