import {
  GetVariantLogResponse,
  InputType,
  OutputStatus,
  OutputType,
  PostVariantPayload,
  Variant,
} from '@adsk/offsite-dc-sdk';
import { mockFolder1, mockFolder1a, mockFolder1b } from './mockFolders';
import { mockProductWithRules2, mockProductWithRules3, mockProjectIdWithProducts } from './mockProducts';
import { mockProject } from './mockProjects';

export const mockGetVariantResponse: Variant = {
  contentId: '611a71f9-3d89-4faf-a3ea-918d84fa65c8',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  release: 1,
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: '',
  schemaVersion: 1,
  tenancyId: 'mock-tenancy-id',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
      status: OutputStatus.PENDING,
      category: 'mock-category',
      family: 'mock-family',
    },
    {
      type: OutputType.THUMBNAIL,
      status: OutputStatus.PENDING,
      modelState: 'Master',
    },
  ],
  createdAt: '',
  updatedAt: '',
};

export const mockPostVariantPayload: PostVariantPayload = {
  inputs: [
    {
      name: 'width',
      value: '5',
    },
    {
      name: 'depth',
      value: '6',
    },
  ],
  outputs: [
    {
      modelState: 'Master',
      type: OutputType.RFA,
    },
  ],
};

export const mockPostVariantResponse: Variant = {
  contentId: '611a71f9-3d89-4faf-a3ea-918d84fa65c8',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  tenancyId: 'mock-tenancy-id',
  release: 1,
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: '',
  schemaVersion: 1,
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
      status: OutputStatus.PENDING,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
      category: 'mock-category',
      family: 'mock-family',
    },
  ],
  createdAt: '',
  updatedAt: '',
};

export const mockTransformedVariantPayload: PostVariantPayload = {
  inputs: [
    { name: 'HasDoor', value: true },
    { name: 'HasDoorControllingInput', value: true },
    { name: 'HasDoorReadOnly', value: true },
    { name: 'Width', value: 38 },
    { name: 'WidthReadOnly', value: 38 },
    { name: 'MockIProperty', value: 'mock iProperty value' },
    { name: 'MockMultiValueNumeric', value: 2 },
    { name: 'MockMultiValueNumericReadOnly', value: 2 },
    { name: 'MockMultiValueText', value: 'text2' },
    { name: 'MockMultiValueTextReadOnly', value: 'text2' },
  ],
  outputs: [
    { type: OutputType.RFA, modelState: 'BIM' },
    { type: OutputType.RFA, modelState: 'Master' },
  ],
};

export const mockGetVariantLogsResponse: GetVariantLogResponse = {
  urn: 'ODkwYjVjYzUtNmZiNS00NzhhLWEwM2YtNjIxNDEzY2FmZTgyL0xvZ3MvbG9nLXJlcG9ydC50eHQvYjYyNzM4MzAtMWY1Mi00YjM0LWJjYTctMDhmNzUwMGU5OWJl',
  createdAt: '2023-01-23T22:48:19.454Z',
};

export const mockVariant: Variant = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  release: 1,
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: 'thumbnail-for-mock-variant-response',
  schemaVersion: 1,
  tenancyId: mockProjectIdWithProducts,
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
      category: 'mock-category',
      family: 'mock-family',
    },
    {
      type: OutputType.THUMBNAIL,
      status: OutputStatus.SUCCESS,
      urn: 'thumbnail-for-mock-variant-response',
      modelState: 'Master',
    },
    {
      type: OutputType.BOM,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'mock urn for bom',
    },
  ],
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-28T16:34:41.773',
};

export const mockTransformedInPostVariantPayload: PostVariantPayload = {
  variantName: 'mockVariantName',
  inputs: [
    {
      name: 'width',
      value: 5,
    },
    {
      name: 'depth',
      value: 6,
    },
  ],
  outputs: [
    {
      type: OutputType.BOM,
      modelState: 'Primary',
      uploadBomOutput: true,
      numberOfBomsToAggregate: 0,
    },
  ],
  release: 1,
};

export const mockVariant2: Variant = {
  contentId: '22222222-cc9c-44a4-ad79-b509e1c3225f',
  variantId: 'mock variant id',
  release: 1,
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Door Width',
      name: 'Width',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Door Width - Read Only',
      name: 'WidthReadOnly',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      type: InputType.IPROPERTY,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
  ],
  outputs: [
    { modelState: 'Primary', type: OutputType.BOM, status: OutputStatus.PENDING },
    {
      category: 'Balcony 0',
      family: 'Balcony  0',
      modelState: 'Master',
      type: OutputType.RFA,
      status: OutputStatus.PENDING,
    },
  ],
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  name: '0 - AD - Balcony Assembly - Inventor 2022',
  schemaVersion: 1,
  tenancyId: mockProjectIdWithProducts,
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-28T16:34:41.773',
};

export const mockVariant3 = {
  ...mockVariant,
  contentId: '33333333-cc9c-44a4-ad79-b509e1c3225f',
};

export const mockVariant4WithRules: Variant = {
  ...mockVariant,
  tenancyId: mockProductWithRules2.tenancyId,
  contentId: mockProductWithRules2.contentId as string,
  name: 'mockVariant4WithRules',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      value: false,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Door Width',
      name: 'Width',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 34,
      visible: true,
    },
    {
      label: 'Door Width - Read Only',
      name: 'WidthReadOnly',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      type: InputType.IPROPERTY,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 4,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text3',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
      category: 'mock-category',
      family: 'mock-family',
    },
    {
      type: OutputType.THUMBNAIL,
      status: OutputStatus.SUCCESS,
      urn: 'thumbnail-for-mock-variant-response',
      modelState: 'Master',
    },
    {
      type: OutputType.BOM,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'mock urn for bom',
    },
  ],
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-27T16:34:41.773',
};

export const mockVariant1ForMockProductWithRules3: Variant = {
  contentId: mockProductWithRules3.contentId,
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw2',
  release: 1,
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: 'thumbnail-for-mock-variant-response',
  schemaVersion: 1,
  tenancyId: mockProductWithRules3.tenancyId,
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Door Width',
      name: 'Width',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Door Width - Read Only',
      name: 'WidthReadOnly',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      type: InputType.IPROPERTY,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
      category: 'mock-category',
      family: 'mock-family',
    },
    {
      type: OutputType.THUMBNAIL,
      status: OutputStatus.SUCCESS,
      urn: 'thumbnail-for-mock-variant-response',
      modelState: 'Master',
    },
    {
      type: OutputType.BOM,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'mock urn for bom',
    },
  ],
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-28T16:34:41.773',
};

export const mockBatchFetchVariantQuery = [
  {
    productId: mockVariant.contentId,
    projectId: mockVariant.tenancyId,
  },
  {
    productId: mockVariant2.contentId,
    projectId: mockVariant2.tenancyId,
  },
  {
    productId: mockVariant3.contentId,
    projectId: mockVariant3.tenancyId,
  },
];
export const mockBatchFetchVariantQueryWithError = [
  {
    productId: 'product id with error',
    projectId: 'project id with error',
  },
];

export const mockVariantsList = [mockVariant, mockVariant2, mockVariant3];

export const mockVariantWithDCSDK: Variant = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  release: 1,
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: 'thumbnail-for-mock-variant-response',
  schemaVersion: 1,
  tenancyId: mockProjectIdWithProducts,
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: InputType.NUMERIC,
      unit: 'm',
      visible: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
      category: '',
      family: '',
    },
    {
      type: OutputType.THUMBNAIL,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'thumbnail-for-mock-variant-response',
    },
    {
      type: OutputType.BOM,
      modelState: 'Master',
      status: OutputStatus.SUCCESS,
      urn: 'mock urn for bom',
    },
  ],
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-28T16:34:41.773',
};

export const mockVariantWithSDKType: Variant = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  variantId: 'mock-variant-id',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Door Width - Read Only',
      name: 'WidthReadOnly',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      type: InputType.IPROPERTY,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
    {
      label: 'Door Width',
      name: 'Width',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
  ],
  name: 'mockProductWithRules3',
  outputs: [
    {
      modelState: 'Primary',
      type: OutputType.BOM,
      status: OutputStatus.PENDING,
    },
    {
      category: 'Balcony 0',
      family: 'Balcony  0',
      modelState: 'BIM',
      type: OutputType.RFA,
      status: OutputStatus.FAILED,
    },
    {
      category: 'Balcony 0',
      family: 'Balcony  0',
      modelState: 'Master',
      type: OutputType.RFA,
      status: OutputStatus.SUCCESS,
    },
  ],
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '2023-01-23T22:48:19.454Z',
  updatedAt: '',
};

export const mockVariantsListDCSDK = [mockVariantWithDCSDK, mockVariantWithSDKType];
