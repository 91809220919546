import {
  DCTemplateContext,
  DCInput,
  InputType,
  DCOutput,
  DynamicContent,
  ProductRelease,
  ReleaseStatus,
  VariantInput,
  VariantOutput,
  OutputStatus,
  Variant,
  VariantLog,
  OutputVendorContextMap,
  Vendors,
  ProductUserConfiguration,
  PutProductUserConfigurationPayload,
  UploadURLsPayload,
  SignedUploadUrlsResponse,
  CompleteUploadPayload,
  CompleteUploadResponse,
  DownloadURLPayload,
  DownloadUrlResponse,
  OutputType,
  PostProductPayload,
  PutProductPayload,
  PostVariantInput,
  PostVariantPayload,
  GetVariantLogResponse,
} from '@adsk/offsite-dc-sdk';

//#region
export const mockProjectId = 'mock-project-id';
const mockDataSetLocation = 'mock-data-set-location';
const mockThumbnail = 'mock-thumbnail';
const mockRulesKey = 'mock-rules-key';
const mockCodeBlocksWorkspace = 'mock-codeBlocksWorkspace';
const mockContext: DCTemplateContext = {
  topLevelAssembly: 'Fixed Table Assembly.iam',
  projectFile: 'Fixed Table Assembly.ipj',
  engine: {
    location: 'DA4I',
    version: '2023',
  },
  workspace: {
    folderPath:
      'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
    location: 'BIMDOCS',
  },
};
//#endregion

//#region Mock Product Dataset

export const mockProductInputs: DCInput[] = [
  {
    name: 'table_type',
    type: InputType.TEXT,
    value: '"Dining"',
    visible: true,
    readOnly: false,
  },
  {
    name: 'base_finish',
    type: InputType.TEXT,
    value: 'Black',
    visible: true,
    readOnly: false,
  },
  {
    name: 'table_length',
    type: InputType.NUMERIC,
    value: 14,
    unit: 'cm',
    min: 10,
    max: 20,
    visible: true,
    readOnly: false,
  },
];

export const mockPostVariantInputs: PostVariantInput[] = [
  {
    name: 'table_type',
    value: '"Dining"',
  },
  {
    name: 'base_finish',
    value: 'Black',
  },
  {
    name: 'table_length',
    value: 14,
  },
];

export const mockProductOutputs: DCOutput[] = [
  {
    type: OutputType.RFA,
    options: {
      modelStates: ['Master', 'BIM'],
      category: 'Wall',
      family: 'Wall',
    },
  },
  {
    type: OutputType.BOM,
    options: {
      modelStates: ['Master'],
    },
  },
  {
    type: OutputType.THUMBNAIL,
    options: {
      modelStates: ['Master'],
    },
  },
];

export const mockPostProductPayload: PostProductPayload = {
  name: 'mock-product-name-1',
  dataSetLocation: mockDataSetLocation,
  thumbnail: mockThumbnail,
  schemaVersion: 1,
  context: mockContext,
  inputs: mockProductInputs,
  outputs: mockProductOutputs,
  rulesKey: mockRulesKey,
  codeBlocksWorkspace: mockCodeBlocksWorkspace,
};

export const mockProduct: DynamicContent = {
  tenancyId: mockProjectId,
  contentId: 'mock-content-id-1',
  name: 'mock-product-name-1',
  dataSetLocation: mockDataSetLocation,
  thumbnail: mockThumbnail,
  schemaVersion: 1,
  context: mockContext,
  inputs: mockProductInputs,
  outputs: mockProductOutputs,
  rulesKey: mockRulesKey,
  codeBlocksWorkspace: mockCodeBlocksWorkspace,
  release: 1,
  createdAt: '2017-11-27 11:53:54',
  updatedAt: '2017-11-27 14:53:54',
};

export const mockPutProductPayload: PutProductPayload = {
  name: 'mock-product-name-1',
  dataSetLocation: mockDataSetLocation,
  thumbnail: mockThumbnail,
  schemaVersion: 1,
  context: mockContext,
  inputs: mockProductInputs,
  outputs: mockProductOutputs,
  rulesKey: mockRulesKey,
  codeBlocksWorkspace: mockCodeBlocksWorkspace,
};

//#endregion

//#region Mock Product Release

export const mockProductRelease1: ProductRelease = {
  tenancyId: mockProjectId,
  contentId: 'mock-content-id-1',
  name: 'mock-product-name-1',
  dataSetLocation: mockDataSetLocation,
  thumbnail: mockThumbnail,
  schemaVersion: 1,
  context: mockContext,
  inputs: mockProductInputs,
  outputs: mockProductOutputs,
  rulesKey: mockRulesKey,
  codeBlocksWorkspace: mockCodeBlocksWorkspace,
  release: 1,
  createdAt: '2017-11-27 11:53:54',
  updatedAt: '2017-11-27 14:53:54',
  status: ReleaseStatus.DEFAULT,
};

//#endregion

//#region Mock Variant Dataset

export const mockVariantInputs: VariantInput[] = [
  {
    name: 'table_type',
    type: InputType.TEXT,
    value: '"Dining"',
    visible: true,
  },
  {
    name: 'base_finish',
    type: InputType.TEXT,
    value: 'Black',
    visible: true,
  },
  {
    name: 'table_length',
    value: 14,
    type: InputType.NUMERIC,
    unit: 'cm',
    visible: true,
  },
];

export const mockVariantOutputs: VariantOutput[] = [
  {
    type: OutputType.RFA,
    category: 'Wall',
    family: 'Wall',
    modelState: 'Master',
    status: OutputStatus.PENDING,
    urn: 'mock-urn',
  },
  {
    type: OutputType.BOM,
    modelState: 'Master',
    status: OutputStatus.PENDING,
    urn: 'mock-urn',
  },
  {
    type: OutputType.THUMBNAIL,
    modelState: 'Master',
    status: OutputStatus.PENDING,
    urn: 'mock-urn',
  },
];

export const mockVariant: Variant = {
  tenancyId: mockProjectId,
  contentId: 'mock-content-id-1',
  variantId: 'mock-variant-id-1',
  name: 'mock-product-name-1',
  dataSetLocation: mockDataSetLocation,
  thumbnail: mockThumbnail,
  schemaVersion: 1,
  context: mockContext,
  inputs: mockVariantInputs,
  outputs: mockVariantOutputs,
  release: 1,
  createdAt: '2017-11-27 11:53:54',
  updatedAt: '2017-11-27 14:53:54',
};

export const mockPostVariantPayload: PostVariantPayload = {
  inputs: [
    {
      name: 'table_type',
      value: '"Dining"',
    },
    {
      name: 'base_finish',
      value: 'Black',
    },
    {
      name: 'table_length',
      value: 14,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      modelState: 'Master',
    },
    {
      type: OutputType.BOM,
      modelState: 'Master',
    },
    {
      type: OutputType.THUMBNAIL,
      modelState: 'Master',
    },
  ],
  release: 1,
};

export const mockVariantLog: VariantLog = {
  tenancyId: mockProjectId,
  contentId: 'mock-content-id-1',
  variantId: 'mock-variant-id-1',
  createdAt: '2022-11-27 11:53:54',
  filteredLog: 'FilteredLogFileOssKey',
  fullLog: 'FullLogFileOssKey',
};

export const mockGetVariantLogResponse: GetVariantLogResponse = {
  urn: 'FilteredLogFileOssKey',
  createdAt: '2022-11-27 11:53:54',
};

//#endregion

//#region Mock Product User Configuraiton

export const mockOutputLocations: OutputVendorContextMap = {
  RFA: {
    vendor: Vendors.ACC,
    accountId: 'mock-account-id',
    projectId: mockProjectId,
    outputLocation: 'mock-rfa-output-location',
  },
  BOM: {
    vendor: Vendors.BIM360,
    accountId: 'mock-account-id',
    projectId: mockProjectId,
    outputLocation: 'mock-bom-output-location',
  },
  THUMBNAIL: {
    vendor: Vendors.ACC,
    accountId: 'mock-account-id',
    projectId: mockProjectId,
    outputLocation: 'mock-thumbnail-output-location',
  },
};

export const mockProductUserConfiguration: ProductUserConfiguration = {
  tenancyId: mockProjectId,
  contentId: 'mock-product-id',
  outputLocations: mockOutputLocations,
  createdAt: '2017-11-27 11:53:54',
  updatedAt: '2017-11-27 14:53:54',
};

export const mockProductUserConfigurationPayload: PutProductUserConfigurationPayload = {
  outputLocations: mockOutputLocations,
};

//#endregion

//#region

export const mockUploadURLsPayload: UploadURLsPayload = {
  fileName: 'mock-filename',
  numberOfParts: 1,
  category: 'mock-category',
};

export const mockUploadURLsResponse: SignedUploadUrlsResponse = {
  urls: ['https://s3.aws.com/path/to/thumbnail.png'],
  uploadKey: 'mock-upload-key',
  uploadExpiration: 111,
  urlExpiration: 999,
  objectKey: 'mock-object-key',
};

export const mockCompleteUploadPayload: CompleteUploadPayload = {
  contentType: 'mock-content-type',
  fileName: 'mock-file-name',
  fileSize: 123,
  objectKey: 'mock-object-key',
  uploadKey: 'mock-upload-key',
};

export const mockCompleteUploadResponse: CompleteUploadResponse = {
  contentType: 'mock-content-type',
  fileName: 'mock-file-name',
  fileSize: 123,
  objectKey: 'mock-object-key',
};

export const mockDownloadURLPayload: DownloadURLPayload = {
  fileName: 'mock-file-name',
  objectKey: 'mock-object-key',
};

export const mockDownloadURLResponse: DownloadUrlResponse = {
  signedUrl: 'mock-signed-url',
};

//#endregion
