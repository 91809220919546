import { ProductRelease, ReleaseStatusValue, ReleaseStatus, DynamicContent } from '@adsk/offsite-dc-sdk';
import DataContext from 'context/Data/Data.context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCancellablePromise } from 'mid-react-common';
import { useContext, useCallback, useEffect, useState } from 'react';
import { logError } from 'mid-utils';
import { getDcApiServiceInstance } from 'mid-api-services';

interface UseReleasesSelectionsProps {
  projectId: string | undefined;
  selectedProduct: DynamicContent | undefined;
}
interface UseReleasesSelectionsState {
  selectedReleaseStatus: ReleaseStatusValue | undefined;
  selectedRelease: number | undefined;
  setSelectedRelease: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleReleaseSelection: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const useReleasesSelection = ({ projectId, selectedProduct }: UseReleasesSelectionsProps): UseReleasesSelectionsState => {
  const dcApiService = getDcApiServiceInstance();
  const cancellablePromise = useCancellablePromise();
  const { setCurrentProductRelease, nonObsoleteProductReleasesList, setProductReleasesList } = useContext(DataContext);
  const { enableReleasesSelection } = useFlags();
  const [selectedRelease, setSelectedRelease] = useState<number>();
  const [selectedReleaseStatus, setSelectedReleaseStatus] = useState<ReleaseStatusValue>();

  const handleReleaseSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const release = event.currentTarget.innerText;
    setSelectedRelease(Number(release));
  };

  const handleReleaseSelectionStatus = useCallback(() => {
    const selectedProductRelease = nonObsoleteProductReleasesList?.find((release) => release.release === selectedRelease);
    if (selectedProductRelease) {
      setSelectedReleaseStatus(selectedProductRelease.status);
      setCurrentProductRelease(selectedProductRelease);
    }
  }, [nonObsoleteProductReleasesList, selectedRelease, setCurrentProductRelease]);

  useEffect(() => {
    const fetchReleases = async () => {
      if (!projectId || !selectedProduct) {
        return;
      }

      let releases: ProductRelease[] = [];
      try {
        releases = await cancellablePromise(dcApiService.getProductReleasesList(projectId, selectedProduct.contentId));
        setProductReleasesList(releases);
      } catch (error) {
        logError(error);
      }
    };

    if (projectId && selectedProduct && enableReleasesSelection) {
      fetchReleases();
    }
  }, [projectId, selectedProduct, dcApiService, cancellablePromise, enableReleasesSelection, setProductReleasesList]);

  useEffect(() => {
    if (nonObsoleteProductReleasesList && nonObsoleteProductReleasesList.length > 0) {
      const defaultRelease = nonObsoleteProductReleasesList.find((release) => release.status === ReleaseStatus.DEFAULT);
      if (defaultRelease) {
        setSelectedRelease(defaultRelease.release);
      }
    }
  }, [nonObsoleteProductReleasesList, setSelectedRelease]);

  useEffect(() => {
    if (selectedRelease) {
      handleReleaseSelectionStatus();
    }
  }, [selectedRelease, handleReleaseSelectionStatus]);

  return { selectedRelease, setSelectedRelease, handleReleaseSelection, selectedReleaseStatus };
};

export default useReleasesSelection;
