import React from 'react';
import theme from '../../global/midTheme';
import Dropdown from '../Dropdown/Dropdown';
import useMultiValueList from './useMultiValueList';

interface MultiValueListProps {
  items: string[] | number[];
  value: string | number | undefined;
  readOnly?: boolean;
  error?: boolean;
  label: string;
  onSelectionChange: (newValue: string | number) => void;
}

const MultiValueList: React.FC<MultiValueListProps> = ({
  items,
  value,
  readOnly = false,
  onSelectionChange,
  label,
  error,
}) => {
  const { dropdownItems, handleOnSelect, selectedDropdownItem } = useMultiValueList({
    onSelectionChange,
    items,
    value,
  });

  return (
    <Dropdown
      label={label}
      elements={dropdownItems}
      error={error}
      onSelect={handleOnSelect}
      selectedItem={selectedDropdownItem}
      disabled={readOnly}
      width={`${theme.var.wideInputControl}px`}
      doubleQuotesControl
    />
  );
};

export default MultiValueList;
