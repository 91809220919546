import { ErrorCode } from './errorCode';
import { CustomError } from './customError';
import { DynamicContent } from '@adsk/offsite-dc-sdk';

type CauseType = {
  currentProduct?: DynamicContent;
};

export class ProductError extends CustomError {
  errorCode = ErrorCode.ProductError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
