import { ReactNode } from 'react';
import { useAnalytics } from './useAnalytics';
import { AppLoadingProgress } from '../../global/styles/Common.styles';
import { IHandleAmplitudeIdentify, ISetAmplitudeGroup } from 'mid-utils';

interface UserAnalyticsProviderProps {
  children: ReactNode;
  handleAmplitudeIdentify?: IHandleAmplitudeIdentify;
  setAmplitudeGroup?: ISetAmplitudeGroup;
  webComponentMoniker: string;
}

const UserAnalyticsProvider: React.FC<UserAnalyticsProviderProps> = ({
  children,
  handleAmplitudeIdentify,
  setAmplitudeGroup,
  webComponentMoniker,
}) => {
  // Set analytics for Bugsnag, Amplitude and Launchdarkly
  const { isAnalyticsIdIdentifiedInLD } = useAnalytics(webComponentMoniker, handleAmplitudeIdentify, setAmplitudeGroup);

  if (!isAnalyticsIdIdentifiedInLD) {
    return <AppLoadingProgress />;
  }

  return children;
};

export default UserAnalyticsProvider;
