import React from 'react';
import text from 'revit.text.json';
import { useTheme } from '@mui/material/styles';
import { Dropdown, DropdownItem, RightButton } from 'mid-react-common';
import { VariantFormState, VariantFormStates } from 'mid-react-common/revit-components';
import { productDetailsFooterTestId } from 'tests/helpers/dataTestIds';
import { InsertFooterWrapper } from './InsertFooter.styles';

type InsertFooterProps = {
  handleInsertRFA: () => void;
  handleRfaRepresentationSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
  variantFormState: VariantFormStates;
  isFormDataValid: boolean;
  representationDropdownItems: DropdownItem[];
  selectedRepresentationDropdownItem: DropdownItem | null;
};

const InsertFooter: React.FC<InsertFooterProps> = ({
  handleInsertRFA,
  handleRfaRepresentationSelection,
  variantFormState,
  isFormDataValid,
  representationDropdownItems,
  selectedRepresentationDropdownItem,
}) => {
  const theme = useTheme();

  return (
    <InsertFooterWrapper justifyContent="end">
      {!!representationDropdownItems.length && (
        <Dropdown
          elements={representationDropdownItems}
          label={text.representations}
          selectedItem={selectedRepresentationDropdownItem}
          width={`${theme.var.wideInputControl}px`}
          disabled={
            representationDropdownItems.length === 1 ||
            variantFormState === VariantFormState.EDITING_NEW_VARIANT ||
            variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
            variantFormState === VariantFormState.INSERTING_VARIANT
          }
          onSelect={handleRfaRepresentationSelection}
        />
      )}
      <RightButton
        data-testid={productDetailsFooterTestId.insertVariantButton}
        width={theme.var.narrowInputControl}
        variant="contained"
        onClick={handleInsertRFA}
        disabled={
          variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
          variantFormState === VariantFormState.EDITING_NEW_VARIANT ||
          variantFormState === VariantFormState.INSERTING_VARIANT ||
          variantFormState === VariantFormState.VARIANT_RFA_OUTPUT_PENDING ||
          variantFormState === VariantFormState.VARIANT_REPLACED_OR_INSERTED ||
          !isFormDataValid
        }
      >
        {text.insert}
      </RightButton>
    </InsertFooterWrapper>
  );
};

export default InsertFooter;
