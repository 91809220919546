/* eslint-disable new-cap */
import { HostApi, Result } from '../interfaces/cefSharp';
import {
  Base64Info,
  DialogStatus,
  ReadFromFileUsingDialogResult,
  SaveToFileUsingDialogResult,
} from '../interfaces/fileSystem';
import { GenerateOutputsResult } from '../interfaces/inventorAutomation';
import { InventorProperties } from '../interfaces/inventorProperties';
import { CachedFileInfo } from '../interfaces/localCache';
import { Environment } from 'mid-types';
import { LogLevel } from '../interfaces/log';
import { InventorActiveDocumentInfo } from '../interfaces/inventorActiveDocumentInfo';
import text from '../mid-addin-lib.text.json';

declare let hostApi: HostApi;

declare global {
  interface Window {
    chrome: {
      webview: {
        hostObjects: {
          hostApi: any;
        };
      };
    };
    hostApi: any;
  }
}

class BrowserApiService implements HostApi {
  private isCefSharp?: boolean = void 0;

  private webview2Host = window.chrome?.webview?.hostObjects.hostApi;

  isCefSharpAvailable(): boolean | undefined {
    if (this.isCefSharp === void 0) {
      // if the browser is Cefsharp, we will register a hostApi object from C#, so window['hostApi'] should exist.
      // if the browser is Webview2, the window['hostApi'] should be undefined
      if (window['hostApi']) {
        this.isCefSharp = true;
      } else {
        this.isCefSharp = false;
      }
    }
    return this.isCefSharp;
  }

  async getOAuth2Token(): Promise<string | null> {
    return this.isCefSharpAvailable() ? hostApi.getOAuth2Token() : await this.webview2Host?.GetOAuth2Token();
  }

  async loadProductDefinitions(): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.loadProductDefinitions();
    }
    const result = await this.webview2Host.LoadProductDefinitions().sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveProductDefinitions(productDefinitions: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.saveProductDefinitions(productDefinitions);
    }
    const result = await this.webview2Host.SaveProductDefinitions(productDefinitions).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getThumbnailImage(documentPath: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getThumbnailImage(documentPath);
    }
    const result = await this.webview2Host.GetThumbnailImage(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getPartOrAssemblyProperties(path: string): Promise<Result<InventorProperties>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getPartOrAssemblyProperties(path);
    }
    const result = await this.webview2Host.GetPartOrAssemblyProperties(path).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const properties: InventorProperties = JSON.parse(result.content);
    return { value: properties, errorMessage: result.errorMessage };
  }

  async selectFolder(topFolder: string): Promise<string> {
    return this.isCefSharpAvailable()
      ? hostApi.selectFolder(topFolder)
      : await this.webview2Host.SelectFolder(topFolder).sync();
  }

  async selectFile(topFolder: string, filter: string, multiSelect: boolean): Promise<string[]> {
    return this.isCefSharpAvailable()
      ? hostApi.selectFile(topFolder, filter, multiSelect)
      : await this.webview2Host.SelectFile(topFolder, filter, multiSelect).sync();
  }

  async getEnvironment(): Promise<Environment> {
    return this.isCefSharpAvailable() ? hostApi.getEnvironment() : await this.webview2Host.GetEnvironment().sync();
  }

  async getModelStates(documentPath: string): Promise<Result<string[]>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getModelStates(documentPath);
    }
    const result = await this.webview2Host.GetModelStates(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getDcApiUrl(): Promise<string> {
    return this.isCefSharpAvailable() ? hostApi.getDcApiUrl() : await this.webview2Host.GetDcApiUrl().sync();
  }

  async getMIDWebAppUrl(): Promise<string> {
    return this.isCefSharpAvailable() ? hostApi.getMIDWebAppUrl() : await this.webview2Host.GetMIDWebAppUrl().sync();
  }

  async fileToBase64String(filePath: string): Promise<Result<Base64Info>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.fileToBase64String(filePath);
    }
    const result = await this.webview2Host.FileToBase64String(filePath).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async compressFolder(folderPath: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.compressFolder(folderPath);
    }
    const result = await this.webview2Host.CompressFolder(folderPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async extractZipFileToFolder(zipFile: string, targetFolderPath: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.extractZipFileToFolder(zipFile, targetFolderPath);
    }
    const result = await this.webview2Host.ExtractZipFileToFolder(zipFile, targetFolderPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async generateOutputs(
    topFolderPath: string,
    documentPath: string,
    inputs: string,
    requestedOutputs: string,
  ): Promise<GenerateOutputsResult> {
    if (this.isCefSharpAvailable()) {
      return hostApi.generateOutputs(topFolderPath, documentPath, inputs, requestedOutputs);
    }
    const result = await this.webview2Host.GenerateOutputs(topFolderPath, documentPath, inputs, requestedOutputs).sync();
    const outputsResult: GenerateOutputsResult = { success: result.success, report: result.report };
    if (result.outputFiles) {
      outputsResult.outputFiles = [];
      for (const proxy of result.outputFiles) {
        outputsResult.outputFiles.push(this.getValueFromProxy({ type: '', modelState: '', filePath: '' }, proxy));
      }
    }
    return outputsResult;
  }

  async insertRFA(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
    release: number,
    modelState: string,
    inputs: string,
  ): Promise<void> {
    if (this.isCefSharpAvailable()) {
      hostApi.insertRFA(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
        release,
        modelState,
        inputs,
      );
    } else {
      const applicationVersion = await this.webview2Host.GetApplicationVersionNumber().sync();
      if (applicationVersion === text.revit2024) {
        // Needed because of a bug in the WebView2 version we use for Revit 2024 add-in.
        // The bug causes Revit to crash when C# method has Task as a return type.
        await this.webview2Host.insertRFA(
          tenancyId,
          contentId,
          variantId,
          rfaSignedUrl,
          familyName,
          category,
          engineVersion,
          release,
          modelState,
          inputs,
        );
      } else if (applicationVersion === text.revit2025) {
        await this.webview2Host.InsertRFA(
          tenancyId,
          contentId,
          variantId,
          rfaSignedUrl,
          familyName,
          category,
          engineVersion,
          release,
          modelState,
          inputs,
        );
      }
    }
  }

  async downloadFileFromUrl(url: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.downloadFileFromUrl(url);
    }
    const result = await this.webview2Host.DownloadFileFromUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async downloadFileFromUrlWithName(url: string, fileName: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.downloadFileFromUrlWithName(url, fileName);
    }
    const result = await this.webview2Host.DownloadFileFromUrlWithName(url, fileName).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getLocallyCached(key: string): Promise<CachedFileInfo[]> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getLocallyCached(key);
    }
    const resultString = await this.webview2Host.GetLocallyCached(key).sync();
    return JSON.parse(resultString);
  }

  async downloadFileToLocalCache(
    key: string,
    signedUrl: string,
    name: string,
    type: string,
    metaDataJson: string | null,
  ): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.downloadFileToLocalCache(key, signedUrl, name, type, metaDataJson)
      : await this.webview2Host.DownloadFileToLocalCache(key, signedUrl, name, type, metaDataJson);
  }

  async writeToCache(key: string, type: string, metaDataJson: string): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.writeToCache(key, type, metaDataJson)
      : await this.webview2Host.WriteToCache(key, type, metaDataJson);
  }

  async getAssemblyVersion(): Promise<string> {
    return this.isCefSharpAvailable() ? hostApi.getAssemblyVersion() : await this.webview2Host.GetAssemblyVersion().sync();
  }

  async openExternalUrl(url: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.openExternalUrl(url);
    }
    const result = await this.webview2Host.OpenExternalUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async deleteFile(filePath: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.deleteFile(filePath);
    }
    const result = await this.webview2Host.DeleteFile(filePath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async openProductDefinitionDocument(documentPath: string, inputs: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.openProductDefinitionDocument(documentPath, inputs);
    }
    const result = await this.webview2Host.OpenProductDefinitionDocument(documentPath, inputs).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async isDocumentOpenInTheEditor(documentPath: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.isDocumentOpenInTheEditor(documentPath);
    }
    const result = await this.webview2Host.IsDocumentOpenInTheEditor(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async checkAndGenerateThumbnailInBase64(filePath: string, documentPath: string): Promise<Result<Base64Info>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.checkAndGenerateThumbnailInBase64(filePath, documentPath);
    }
    const result = await this.webview2Host.CheckAndGenerateThumbnailInBase64(filePath, documentPath).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async getApplicationVersionNumber(): Promise<string> {
    return this.isCefSharpAvailable()
      ? hostApi.getApplicationVersionNumber()
      : await this.webview2Host.GetApplicationVersionNumber().sync();
  }

  async saveToFile(content: string, fileName: string, fileExtension: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.saveToFile(content, fileName, fileExtension);
    }
    const result = await this.webview2Host.SaveToFile(content, fileName, fileExtension).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveToFileUsingDialog(
    content: string,
    fileName: string,
    fileLocation: string,
    filter: string,
    title: string,
    skipDialog: boolean,
  ): Promise<Result<SaveToFileUsingDialogResult>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.saveToFileUsingDialog(content, fileName, fileLocation, filter, title, skipDialog);
    }
    const result = await this.webview2Host
      .SaveToFileUsingDialog(content, fileName, fileLocation, filter, title, skipDialog)
      .sync();
    if (!result.content && result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const saveToFileUsingDialogResult: SaveToFileUsingDialogResult = { status: DialogStatus.error };
    this.getValueFromProxy(saveToFileUsingDialogResult, result.content);
    return { value: saveToFileUsingDialogResult, errorMessage: result.errorMessage };
  }

  async readFromFileUsingDialog(
    fileName: string,
    fileLocation: string,
    filter: string,
    title: string,
    skipDialog: boolean,
  ): Promise<Result<ReadFromFileUsingDialogResult>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.readFromFileUsingDialog(fileName, fileLocation, filter, title, skipDialog);
    }
    const result = await this.webview2Host.ReadFromFileUsingDialog(fileName, fileLocation, filter, title, skipDialog).sync();
    if (!result.content && result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const readFromFileUsingDialogResult: ReadFromFileUsingDialogResult = {
      status: DialogStatus.error,
      content: '',
    };
    this.getValueFromProxy(readFromFileUsingDialogResult, result.content);
    return { value: readFromFileUsingDialogResult, errorMessage: result.errorMessage };
  }

  async getSelectedRFAInstance(): Promise<string> {
    if (this.isCefSharpAvailable()) {
      return await hostApi.getSelectedRFAInstance();
    }
    const result = await this.webview2Host.GetSelectedRFAInstance().sync();
    return result;
  }

  async getAllMIDInstancesData(): Promise<string> {
    if (this.isCefSharpAvailable()) {
      return await hostApi.getAllMIDInstancesData();
    }
    const result = await this.webview2Host.GetAllMIDInstancesData().sync();
    return result;
  }

  async selectAndEditMidInstance(elementId: string): Promise<Result<boolean>> {
    // NOTE: No CeFSharp implementation for this method as 2023 support has been discontinued.

    // TODO: This is temporary only to please Cypress
    // I have a fix which will come in a subsequenet ticket TRADES-5920
    // to have Cypress mock the Webview2 implementation instead of HostApi
    // because it will affect all webapps.
    if (this.isCefSharpAvailable()) {
      return await hostApi.selectAndEditMidInstance(elementId);
    }
    const result = await this.webview2Host.SelectAndEditMidInstance(elementId).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  /**
   * Converts a webView2 proxy result into a TypeScript object.
   */
  getValueFromProxy(value: any, proxy: any): any {
    for (const property in value) {
      value[property] = proxy[property];
    }
    return value;
  }

  async editInstance(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
    release: number,
    modelState: string,
    inputs: string,
  ): Promise<void> {
    if (this.isCefSharpAvailable()) {
      hostApi.editInstance(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
        release,
        modelState,
        inputs,
      );
    } else {
      const applicationVersion = await this.webview2Host.GetApplicationVersionNumber().sync();
      if (applicationVersion === text.revit2024) {
        // Needed because of a bug in the WebView2 version we use for Revit 2024 add-in.
        // The bug causes Revit to crash when C# method has Task as a return type.
        await this.webview2Host.editInstance(
          tenancyId,
          contentId,
          variantId,
          rfaSignedUrl,
          familyName,
          category,
          engineVersion,
          release,
          modelState,
          inputs,
        );
      } else if (applicationVersion === text.revit2025) {
        await this.webview2Host.EditInstance(
          tenancyId,
          contentId,
          variantId,
          rfaSignedUrl,
          familyName,
          category,
          engineVersion,
          release,
          modelState,
          inputs,
        );
      }
    }
  }

  async getDrawingFiles(topFolder: string): Promise<string[]> {
    return this.isCefSharpAvailable()
      ? hostApi.getDrawingFiles(topFolder)
      : await this.webview2Host.GetDrawingFiles(topFolder).sync();
  }

  async logToFile(message: string, logLevel: LogLevel): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.logToFile(message, logLevel)
      : await this.webview2Host.LogToFile(message, logLevel);
  }

  async resizeWindow(width: number, height: number): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.resizeWindow(width, height)
      : await this.webview2Host.ResizeWindow(width, height).sync();
  }

  async getActiveDocumentInfo(): Promise<Result<InventorActiveDocumentInfo>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getActiveDocumentInfo();
    }
    const result = await this.webview2Host.GetActiveDocumentInfo().sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const inventorActiveDocumentInfo: InventorActiveDocumentInfo = { location: '', name: '' };
    this.getValueFromProxy(inventorActiveDocumentInfo, result.content);
    return { value: inventorActiveDocumentInfo, errorMessage: result.errorMessage };
  }

  async writeToPublishData(publishDataJson: string): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.writeToPublishData(publishDataJson)
      : await this.webview2Host.WriteToPublishData(publishDataJson);
  }

  async isEditDialogOpen(): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return await hostApi.isEditDialogOpen();
    }
    const result = await this.webview2Host.IsEditDialogOpen().sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }
}

export default new BrowserApiService();
