import {
  ForgeJsonApiListResponse,
  JSONAPIBase,
  JSONAPIResponseBase,
  RelatedLink,
  SelfLink,
  WebviewLink,
} from './dmApi.types';

export const hubFileTypes = {
  BIM360_DOCUMENT: 'items:autodesk.bim360:Document',
  BIM360_FILE: 'items:autodesk.bim360:File',
  BIM360_REVIEW_DOCUMENT: 'items:autodesk.bim360:ReviewDocument',
  BIM360_FOLDER: 'folders:autodesk.bim360:Folder',
  BIM360_TITLE_BLOCK: 'items:autodesk.bim360:TitleBlock',
} as const;

const visibleFileTypes = [hubFileTypes.BIM360_DOCUMENT, hubFileTypes.BIM360_FILE] as const;

type VisibleFileType = (typeof visibleFileTypes)[number];

type AllFileType = (typeof hubFileTypes)[keyof typeof hubFileTypes];

const fileActions = {
  CONVERT: 'CONVERT',
  SPLIT: 'SPLIT',
  OCR: 'OCR',
} as const;

type FileAction = (typeof fileActions)[keyof typeof fileActions];

export const contentTypes = {
  FOLDERS: 'folders',
  ITEMS: 'items',
} as const;

type ContentType = (typeof contentTypes)[keyof typeof contentTypes];

export const hubProjectFolderTypes = {
  FUSION: 'folders:autodesk.core:Folder',
  BIM360_OR_ACC: 'folders:autodesk.bim360:Folder',
} as const;

type HubProjectFolderType = (typeof hubProjectFolderTypes)[keyof typeof hubProjectFolderTypes];

type HubProjectFolderNode = {
  type: ContentType;
  id: string;
};

export type HubProjectFolderContentLinks = {
  links: (SelfLink & WebviewLink) | SelfLink;
  relationships: {
    contents: { links: RelatedLink };
    parent: {
      data: {
        type: string;
        id: string;
      };
      links: RelatedLink;
    };
    refs: {
      links: RelatedLink & SelfLink;
    };
    links: {
      links: SelfLink;
    };
  };
};

export type HubProjectFolderContent = {
  type: ContentType;
  id: string;
  attributes: {
    name?: string;
    displayName: string;
    createTime: string;
    createUserId: string;
    createUserName: string;
    lastModifiedTime: string;
    lastModifiedUserId: string;
    lastModifiedUserName: string;
    lastModifiedTimeRollup: string;
    objectCount: number;
    hidden: boolean;
    extension: {
      type: AllFileType;
      version: string;
      schema: {
        href: string;
      };
      data: {
        visibleTypes: VisibleFileType[];
        actions: FileAction[];
        allowedTypes: AllFileType[];
        isRoot: boolean;
        folderType: string;
        namingStandardIds: [];
      };
    };
  };
} & HubProjectFolderContentLinks;

export type HubProjectFolderPostData = {
  type: ContentType;
  attributes: {
    name: string;
    extension: {
      type: HubProjectFolderType;
      version: string;
    };
  };
  relationships: {
    parent: {
      data: HubProjectFolderNode;
    };
  };
};

export type HubProjectFolderContentResponse = ForgeJsonApiListResponse<HubProjectFolderContent>;

export type HubProjectFolderResponse = JSONAPIResponseBase & {
  // When there are errors, the "data" key is not present
  data?: HubProjectFolderContent;
};

export type HubProjectFolderPostRequest = JSONAPIBase & {
  data: HubProjectFolderPostData;
};

export const folderPermissionActions = {
  READ: 'read',
  VIEW: 'view',
  DOWNLOAD: 'download',
  COLLABORATE: 'collaborate',
  CREATE: 'create',
  UPLOAD: 'upload',
  UPDATE_METADATA: 'updateMetaData',
  ADMIN: 'admin',
  SHARE: 'share',
} as const;

export type FolderPermissionAction = (typeof folderPermissionActions)[keyof typeof folderPermissionActions];

export const COMMAND_TYPE = 'commands';
export const CHECK_PERMISSION_TYPE = 'commands:autodesk.core:CheckPermission' as const;

export type CheckPermissionPostRequest = JSONAPIBase & {
  data: {
    type: typeof COMMAND_TYPE;
    attributes: {
      extension: {
        type: typeof CHECK_PERMISSION_TYPE;
        version: string;
        data: {
          requiredActions: FolderPermissionAction[];
        };
      };
    };
    relationships: {
      resources: {
        data: HubProjectFolderNode[];
      };
    };
  };
};

type FolderPermissionDetail = {
  type: ContentType;
  id: string;
  details: Partial<Record<FolderPermissionAction, boolean>>;
  permission: boolean;
};

type HubProjectFolderMeta = HubProjectFolderNode & {
  meta: {};
};

export type FolderPermissionResult = {
  type: typeof COMMAND_TYPE;
  id: string;
  attributes: {
    status: string;
    extension: {
      type: typeof CHECK_PERMISSION_TYPE;
      version: string;
      schema: {
        href: string;
      };
      data: {
        requiredActions: FolderPermissionAction[];
        permissions: FolderPermissionDetail[];
      };
    };
  };
  relationships: {
    resources: {
      data: HubProjectFolderMeta[];
    };
  };
};

export type CheckPermissionPostResponse = JSONAPIBase & { data: FolderPermissionResult };
