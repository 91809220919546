import { ConfigurableProductPropertiesMap, StateSetter } from 'mid-react-common';
import { ConfigurableProductActions, ConfigurableProductActionTypes } from './dataStore.actions';
import { DCInput, ProductRelease } from '@adsk/offsite-dc-sdk';
import { isDCRfaOutput } from 'mid-utils';

export const initialConfigurableProductPropertiesMap: ConfigurableProductPropertiesMap = {
  inputs: new Map(),
  // TODO: if/when we integrate outputs changes, the outputs below should be changed to a Map
  outputs: [],
};

export const initializeConfigurableProductProperties = (
  currentProductRelease: ProductRelease,
  dispatchConfigurableProductUpdateAction: React.Dispatch<ConfigurableProductActions>,
  setSelectedRepresentation: StateSetter<string | undefined>,
): void => {
  dispatchConfigurableProductUpdateAction({
    type: ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD,
    payload: {
      inputs: currentProductRelease.inputs,
      outputs: currentProductRelease.outputs,
    },
  });

  const rfaOutput = currentProductRelease?.outputs.find(isDCRfaOutput);
  const defaultRepresentation = rfaOutput?.options?.modelStates?.at(0);
  setSelectedRepresentation(defaultRepresentation);
};

export const configurableProductReducer = (
  configurableProductPropertiesMap: ConfigurableProductPropertiesMap,
  action: ConfigurableProductActions,
): ConfigurableProductPropertiesMap => {
  switch (action.type) {
    case ConfigurableProductActionTypes.UPDATE_INPUT: {
      const clonedInputsMap = new Map(configurableProductPropertiesMap.inputs);
      clonedInputsMap.set(action.payload.name, action.payload);

      return { ...configurableProductPropertiesMap, inputs: clonedInputsMap };
    }
    case ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD: {
      const { inputs, outputs } = action.payload;

      return {
        outputs,
        inputs: inputs.reduce((acc: Map<string, DCInput>, input: DCInput) => {
          acc.set(input.name, input);
          return acc;
        }, new Map()),
      };
    }
    case ConfigurableProductActionTypes.UPDATE_ALL_INPUTS: {
      const inputs = action.payload;

      const inputMaps = inputs.reduce((acc: Map<string, DCInput>, input: DCInput) => {
        acc.set(input.name, input);
        return acc;
      }, new Map());

      return { ...configurableProductPropertiesMap, inputs: inputMaps };
    }
    default:
      throw new Error('No Configurable Product Properties action found');
  }
};
