import { useMemo } from 'react';
import { ConfigurableProductProperties, DropdownItem } from 'mid-react-common';
import { isDCRfaOutput, isVariantOutputSuccessfulRfaOutput } from 'mid-utils';
import { DCRfaOutput, Variant } from '@adsk/offsite-dc-sdk';

interface UseRepresentationState {
  productReleaseRFAOutput?: DCRfaOutput;
  selectedRepresentation: string | undefined;
  variantRfaRepresentations: string[];
  rfaRepresentationDropdownItems: DropdownItem[];
  variantRepresentationDropdownItems: DropdownItem[];
  representationDropdownItemsInNewVariantPage: DropdownItem[];
  selectedRepresentationDropdownItem: DropdownItem | null;
  handleRfaRepresentationSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
}

interface useRepresentationProps {
  configurableProductProperties: ConfigurableProductProperties;
  selectedRepresentation: string | undefined;
  setSelectedRepresentation: (selectedRepresentation: string | undefined) => void;
  selectedVariant: Variant | undefined | null;
  handleRepresentationStateChange?: () => void;
}

export const useRepresentation = ({
  configurableProductProperties,
  selectedRepresentation,
  setSelectedRepresentation,
  selectedVariant,
  handleRepresentationStateChange,
}: useRepresentationProps): UseRepresentationState => {
  const { productReleaseRFAOutput, rfaRepresentationDropdownItems } = useMemo(() => {
    const productReleaseRFAOutput = configurableProductProperties.outputs.find(isDCRfaOutput);

    const rfaRepresentationDropdownItems =
      productReleaseRFAOutput?.options?.modelStates?.map((modelState) => ({
        id: modelState,
        value: modelState,
        label: modelState,
      })) || [];

    return { productReleaseRFAOutput, rfaRepresentationDropdownItems };
  }, [configurableProductProperties.outputs]);

  const handleRfaRepresentationSelection = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem && typeof selectedItem.value === 'string') {
      setSelectedRepresentation(selectedItem.value);
      handleRepresentationStateChange && handleRepresentationStateChange();
    }
  };

  const { variantRfaRepresentations, variantRepresentationDropdownItems } = useMemo(() => {
    const variantRfaRepresentations =
      selectedVariant?.outputs.reduce<string[]>((representations, variantOutput) => {
        if (isVariantOutputSuccessfulRfaOutput(variantOutput)) {
          return [...representations, variantOutput.modelState];
        }

        return representations;
      }, []) || [];

    const variantRepresentationDropdownItems = variantRfaRepresentations.map((modelState) => ({
      id: modelState,
      value: modelState,
      label: modelState,
    }));

    return { variantRfaRepresentations, variantRepresentationDropdownItems };
  }, [selectedVariant]);

  const representationDropdownItemsInNewVariantPage = selectedVariant
    ? variantRepresentationDropdownItems
    : rfaRepresentationDropdownItems;

  return {
    productReleaseRFAOutput,
    selectedRepresentation,
    variantRfaRepresentations,
    rfaRepresentationDropdownItems,
    variantRepresentationDropdownItems,
    representationDropdownItemsInNewVariantPage,
    selectedRepresentationDropdownItem: selectedRepresentation
      ? { id: selectedRepresentation, label: selectedRepresentation, value: selectedRepresentation }
      : null,
    handleRfaRepresentationSelection,
  };
};
