import { midShelfCacheKey } from 'mid-utils';
import { HostApi } from '../interfaces/cefSharp';
import { inventorOutputTypes } from '../interfaces/inventorAutomation';
import { CachedFileInfo } from '../interfaces/localCache';
import { LogLevel } from '../interfaces/log';
import { mockProductDefinition3, mockProductDefinitions } from './data/mockProductDefinitions';
import { mockProperties, mockPropertiesInventor2 } from './data/mockParametersAndIProperties';
import { thumbnailsBase64 } from './data/mockThumbnailInBase64';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import { ENVIRONMENT, Environment } from 'mid-types';
import { DialogStatus } from '../interfaces/fileSystem';

const RESPONSE_DELAY = import.meta.env.VITE_CYPRESS_BUILD ? 0 : 1000;
const reactAppEnvironment = (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;

const mockHostApi: HostApi = {
  getOAuth2Token: () => Promise.resolve('mocked-token'),

  loadProductDefinitions: () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({ value: JSON.stringify(mockProductDefinitions), errorMessage: null });
      }, 1000);
    }),

  saveProductDefinitions: (_mockProductDefinitions: string) => Promise.resolve({ value: true, errorMessage: null }),

  getThumbnailImage: (_mockDocumentPath: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          value: 'https://via.placeholder.com/300/000000/FFFFFF/?text=Mocked+Thumbnail',
          errorMessage: null,
        });
      }, RESPONSE_DELAY);
    }),

  getPartOrAssemblyProperties: (_mockPath: string) =>
    new Promise((resolve) => {
      let properties = mockProperties;
      if (_mockPath === mockProductDefinition3.topLevelFolder + mockProductDefinition3.assembly) {
        properties = mockPropertiesInventor2;
      }
      setTimeout(() => {
        resolve({ value: properties, errorMessage: null });
      }, RESPONSE_DELAY);
    }),

  selectFolder: (_mockFolder: string) => Promise.resolve('C:/Workspace/Wall'),

  selectFile(_mockFolder: string, _mockfilter: string, _mockmultiSelect?: boolean): Promise<string[]> {
    if (_mockfilter.includes('*.ipj')) {
      return Promise.resolve(['C:\\path\\to\\folder\\project2\\project.ipj']);
    }
    return Promise.resolve(['C:/Workspace/Wall/Project1/Project2/Assembly.iam']);
  },

  getEnvironment: () => Promise.resolve(reactAppEnvironment),

  getModelStates: (_mockDocumentPath: string) => Promise.resolve({ value: ['Master', 'BIM'], errorMessage: null }),

  getDcApiUrl: () => Promise.resolve(`${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/`),

  getMIDWebAppUrl: () => Promise.resolve(`${ServiceConfigMap[ServiceTypes.MID_WEBAPP][reactAppEnvironment].api}/`),

  fileToBase64String: (_mockFilePath: string) => {
    const thumbnailsKeys = Object.keys(thumbnailsBase64) as (keyof typeof thumbnailsBase64)[];
    const randomIndex = Math.floor(Math.random() * thumbnailsKeys.length);
    return Promise.resolve({
      value: { name: 'fileName.txt', base64: thumbnailsBase64[thumbnailsKeys[randomIndex]] },
      errorMessage: null,
    });
  },

  compressFolder: (_mockFolderPath: string) =>
    Promise.resolve({
      value: 'path/to/zipfile',
      errorMessage: null,
    }),

  extractZipFileToFolder: (_mockZipFile: string, _mockTargetFolderPath: string) =>
    Promise.resolve({
      value: 'path/to/folder',
      errorMessage: null,
    }),

  generateOutputs: (_mockTopFolder: string, _mockDocumentPath: string, _mockInputs: string, _mockModelState: string) =>
    Promise.resolve({
      success: true,
      outputFiles: [
        {
          type: inventorOutputTypes.RFA,
          modelState: 'BIM',
          filePath: 'path/to/assembly [BIM].rfa',
        },
        {
          type: inventorOutputTypes.RFA,
          modelState: '[Primary]',
          filePath: 'path/to/assembly [Primary].rfa',
        },
        {
          type: inventorOutputTypes.THUMBNAIL,
          modelState: '',
          filePath: 'path/to/thumbnail.png',
        },
      ],
      report: '',
    }),

  insertRFA: (
    _mockTenancyId: string,
    _mockContentId: string,
    _mockVariantId: string,
    _mockRfaSignedUrl: string,
    _mockFamilyName: string,
    _mockCategory: string,
    _mockEngineVersion: string,
    _mockReleaseNumber: number,
    _mockModelState: string,
    _mockInputs: string,
  ) => Promise.resolve(),

  downloadFileFromUrl: (_mockUrl: string) => Promise.resolve({ value: 'mock/path/to/file', errorMessage: null }),

  downloadFileFromUrlWithName: (_mockUrl: string, _mockFileName) =>
    Promise.resolve({ value: 'mock/path/to/file', errorMessage: null }),

  getLocallyCached: (_mockKey: string): Promise<CachedFileInfo[]> => {
    if (_mockKey === midShelfCacheKey) {
      return Promise.resolve([]);
    }

    const fileInfo1: CachedFileInfo = {
      key: 'mock-key',
      type: 'RFA',
      filePath: 'mock/path/to/file.rfa',
      modelState: 'Primary',
      category: 'mocked-category',
      family: 'mocked-family',
    };
    const fileInfo2: CachedFileInfo = {
      key: 'mock-key-2',
      type: 'VARIANTINFO',
      filePath: 'mock/path/to/file.rfa',
      name: 'mock cached variant name',
      dataSetLocation: 'mock data set location',
      context: JSON.stringify({
        engine: {
          location: 'DA4I',
          version: '2023',
        },
      }),
    };
    return Promise.resolve([fileInfo1, fileInfo2]);
  },

  downloadFileToLocalCache: (
    _mockKey: string,
    _mockSignedUrl: string,
    _mockName: string,
    _mockType: string,
    _mockMetaDataJson: string | null,
  ) => Promise.resolve(),

  writeToCache: (_mockKey: string, _mockType: string, _mockMetaDataJson: string) => Promise.resolve(),

  getAssemblyVersion: () => Promise.resolve('Version info not available'),

  openExternalUrl: (_mockUrl: string) => {
    window.location.href = _mockUrl;
    return Promise.resolve({ value: true, errorMessage: null });
  },

  deleteFile: (_mockFilePath: string) => Promise.resolve({ value: true, errorMessage: null }),

  openProductDefinitionDocument: (_mockDocumentPath: string, _mockInputs: string) =>
    Promise.resolve({ value: true, errorMessage: null }),

  isDocumentOpenInTheEditor: (_mockDocumentPath: string) => Promise.resolve({ value: true, errorMessage: null }),

  checkAndGenerateThumbnailInBase64: (_mockFilePath: string, _mockDocumentPath: string) => {
    const thumbnailsKeys = Object.keys(thumbnailsBase64) as (keyof typeof thumbnailsBase64)[];
    const randomIndex = Math.floor(Math.random() * thumbnailsKeys.length);
    return Promise.resolve({
      value: { name: 'fileName.txt', base64: thumbnailsBase64[thumbnailsKeys[randomIndex]] },
      errorMessage: null,
    });
  },

  getApplicationVersionNumber: () => Promise.resolve('2024'),
  saveToFile: (_mockContent: string, _mockFileName: string, _mockFileExtension: string) =>
    Promise.resolve({ value: 'someFile', errorMessage: null }),
  saveToFileUsingDialog: (
    _mockContent: string,
    _mockFileName: string,
    _mockLocation: string,
    _mockFileExtension: string,
    _mockTitle: string,
    _mockSkipDialog: boolean,
  ) => Promise.resolve({ value: { status: DialogStatus.success }, errorMessage: null }),
  readFromFileUsingDialog: (
    _mockFileName: string,
    _mockLocation: string,
    _mockFileExtension: string,
    _mockTitle: string,
    _mockSkipDialog: boolean,
  ) =>
    Promise.resolve({
      value: { status: DialogStatus.success, content: 'Test Content' },
      errorMessage: null,
    }),
  getSelectedRFAInstance: () =>
    Promise.resolve(
      JSON.stringify({
        tenancyId: 'mock-tenancy-id',
        contentId: 'mock-content-id',
        variantId: 'mock-variant-id',
        release: 1,
        modelState: 'mock-model-state',
      }),
    ),
  getAllMIDInstancesData: () => Promise.resolve('mock-mid-instances-data'),
  selectAndEditMidInstance: (_mockElementId: string) => Promise.resolve({ value: true, errorMessage: null }),
  editInstance: (
    _mockTenancyId: string,
    _mockContentId: string,
    _mockVariantId: string,
    _mockRfaSignedUrl: string,
    _mockFamilyName: string,
    _mockCategory: string,
    _mockEngineVersion: string,
    _mockReleaseNumber: number,
    _mockModelState: string,
    _mockInputs: string,
  ) => Promise.resolve(),

  getDrawingFiles(mockFolder: string): Promise<string[]> {
    const defaultTopLevelFolder = `C:\\path\\to\\folder\\project2`;
    const topLevelFolder = defaultTopLevelFolder.startsWith(mockFolder) ? defaultTopLevelFolder : mockFolder;

    return Promise.resolve([
      `${topLevelFolder}\\Project1\\Project2\\Assembly.dwg`,
      `${topLevelFolder}\\Project1\\Project2\\Assembly2.dwg`,
      `${topLevelFolder}\\Project1\\Project2\\Assembly3.dwg`,
    ]);
  },

  logToFile: (_mockMessage: string, _mockLevel: LogLevel) => Promise.resolve(),

  resizeWindow: (_mockWidth: number, _mockHeight: number) => Promise.resolve(),

  getActiveDocumentInfo: () =>
    Promise.resolve({
      value: { location: 'C:\\path\\to\\folder\\project2', name: 'assembly.iam' },
      errorMessage: null,
    }),

  writeToPublishData: (_mockMetaDataJson: string) => Promise.resolve(),
  isEditDialogOpen: () => Promise.resolve({ value: true, errorMessage: null }),
};

export default mockHostApi;
