import { OutputTypes } from '@adsk/offsite-dc-sdk';

/**
 * Structure for locally cached file metadata.
 */
export type FileMetaData = { [key: string]: string };

export const VariantInfoType = 'VARIANTINFO' as const;

/**
 * Return value of getLocallyCached.
 * Contains information about a locally cached file.
 */
export type CachedFileInfo = { type: OutputTypes | typeof VariantInfoType; filePath?: string } & FileMetaData;

export type ProductPublishData = {
  productId: string;
  productDefinitionName: string;
  productName: string;
  release: number;
  timeToGenerateOutputs: number;
  timeToUploadDatasetFiles: number;
  timeToPostProduct: number;
  timeToPostVariant: number;
  totalTimeTakenToPublishProduct: number;
};
