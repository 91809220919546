import { ENVIRONMENT, Environment } from 'mid-types';
import { DCInput, InputType, VariantInput } from '@adsk/offsite-dc-sdk';

export const getReactAppEnvironment = (): Environment =>
  (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;

export const removeDoubleQuotesFromStringArray = (array: string[]): string[] =>
  array.map((value) => removeDoubleQuotes(value));
export const removeDoubleQuotes = (value: string): string => value.replace(/^"(.*)"$/, '$1');
export const addDoubleQuotesToStringArray = (array: string[]): string[] => array.map((value) => addDoubleQuotes(value));
export const addDoubleQuotes = (value: string): string => (value.startsWith('"') ? value : `"${value}"`);
export const addQuotesToTextParametersObject = <Input extends DCInput>(
  parameters: Record<string, Input>,
): Record<string, Input> => {
  for (const [key, parameter] of Object.entries(parameters)) {
    if (parameter.type === InputType.TEXT) {
      const unTrimmedValue = addDoubleQuotes(parameter.value);
      const unTrimmedArray = parameter.values ? addDoubleQuotesToStringArray(parameter.values) : parameter.values;
      parameters[key] = { ...parameter, value: unTrimmedValue, values: unTrimmedArray };
    }
  }

  return parameters;
};

const removeQuotes = <Input extends DCInput | VariantInput>(parameter: Input): Input => {
  if (parameter.type === InputType.TEXT) {
    const trimmedValue = removeDoubleQuotes(parameter.value);
    if ('values' in parameter) {
      const trimmedArray = parameter.values ? removeDoubleQuotesFromStringArray(parameter.values) : parameter.values;
      return { ...parameter, value: trimmedValue, values: trimmedArray };
    }
    return { ...parameter, value: trimmedValue };
  }

  return parameter;
};

export const removeQuotesFromTextParametersInArray = <Input extends DCInput | VariantInput>(parameters: Input[]): Input[] =>
  parameters.map(removeQuotes);
