import { useState } from 'react';
import { SpanBlockText } from './PrintMessageDialog.styles';

interface UsePrintMessageDialogState {
  isMessageDialogOpen: boolean;
  dialogMessage: JSX.Element[];
  showMessageDialog: (message: string) => void;
  closeMessageDialog: () => void;
}

const usePrintMessageDialog = (): UsePrintMessageDialogState => {
  const [isMessageDialogOpen, setIsOpenMessageDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<JSX.Element[]>([]);

  const showMessageDialog = (message: string) => {
    setIsOpenMessageDialog(true);
    setDialogMessage((previousMessage: JSX.Element[]) => {
      const updatedMessage = Array.from(previousMessage);
      updatedMessage.push(<SpanBlockText key={updatedMessage.length}>{message}</SpanBlockText>);
      return updatedMessage;
    });
  };

  const closeMessageDialog = () => {
    setIsOpenMessageDialog(false);
    setDialogMessage([]);
  };

  return { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog };
};

export default usePrintMessageDialog;
