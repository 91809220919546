import { MouseEventHandler, ReactNode } from 'react';
import Button from '@mui/material/Button';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import text from '../../mid-react-common.text.json';
import { DialogWrapper } from './CustomDialog.styles';
import commonTestIds from '../../tests/helpers/dataTestIds';

type DataAttributes = {
  [key in `data-${string}`]?: string;
};

interface CustomDialogProps {
  open: boolean;
  height?: number;
  maxWidth?: Breakpoint;
  children: ReactNode;
  title?: string | JSX.Element;
  dividers?: boolean;
  customActions?: ReactNode[];
  showActions?: boolean;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  isConfirmDisabled?: boolean;
  isAddin?: boolean;
}

const CustomDialog = ({
  open,
  height,
  children,
  title,
  maxWidth,
  dividers,
  customActions,
  showActions = true,
  onCancel,
  onConfirm,
  isConfirmDisabled = false,
  isAddin = false,
  ...dataAttributes
}: CustomDialogProps & DataAttributes): JSX.Element => (
  <DialogWrapper fullWidth maxWidth={maxWidth} height={height} open={open} isAddin={isAddin} {...dataAttributes}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers={dividers}>{children}</DialogContent>
    {showActions && (
      <DialogActions>
        {customActions ? (
          <>{customActions}</>
        ) : (
          <>
            <Button variant="outlined" onClick={onCancel} data-testid={commonTestIds.modalCancelTestId}>
              {text.cancel}
            </Button>
            <Button
              variant="contained"
              onClick={onConfirm}
              data-testid={commonTestIds.modalConfirmTestId}
              disabled={isConfirmDisabled}
            >
              {text.confirm}
            </Button>
          </>
        )}
      </DialogActions>
    )}
  </DialogWrapper>
);

export default CustomDialog;
