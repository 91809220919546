import { rest } from 'msw';
import {
  getAccountsHandler,
  getFoldersHandler,
  getProjectsHandler,
  getProjectByIdHandler,
  getSubFoldersHandler,
  getAllProductsInProjectHandler,
  postVariantHandler,
  getVariantHandler,
  postProduct,
  deleteProductTemplateHandler,
  createNewfolder,
  getVariantLog,
  getUserProfile,
  getAnalyticsId,
  getProductHandler,
  getManifestHandler,
  getFolderContentHandler,
  getFolderTreeHandler,
  getMSIMetadata,
  getThumbnailHandler,
  getVariantOutputsHandler,
  getVariantsByProductHandler,
  getDownloadUrlHandler,
  postCompleteUpload,
  postUploadUrls,
  putProductUserConfigurationHandler,
  bypassRequest,
  putUploadUrl,
  getRulesObject,
  getRulesObjectWithError,
  getProductReleasesHandler,
  amplitudeHandler,
} from '../mocks/handlers';
import { getReactAppEnvironment } from '../web-package/utility';
import { ServiceConfigMap, ServiceTypes } from '../constants/serviceAPIConfig';
import { ApiPaths } from '../constants/forgeApiPaths';

const reactAppEnvironment = getReactAppEnvironment();
export const mswForgeApiHandlers = [
  // **********************
  // FORGE API
  // **********************
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.BIM360_API_PATH}/${ApiPaths.ACCOUNTS}`,
    getAccountsHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.ACC_API_PATH}/${
      ApiPaths.ACCOUNTS
    }/:accountId/${ApiPaths.PROJECTS}`,
    getProjectsHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.ACC_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId`,
    getProjectByIdHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders`,
    getFoldersHandler,
  ),
  rest.post(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders`,
    createNewfolder,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders/:folderUrn`,
    getSubFoldersHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders/:folderId/folder_tree`,
    getFolderTreeHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders/:folder/documents`,
    getFolderContentHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${
      ApiPaths.MODEL_DERIVATIVE_PATH
    }/:documentId/manifest`,
    getManifestHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DOCUMENT_THUMBNAIL_PATH}/:documentId`,
    getThumbnailHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.USERPROFILE_API_PATH}/users/@me`,
    getUserProfile,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${
      ApiPaths.IDENTITY_API_PATH
    }/users/:userId/analytics`,
    getAnalyticsId,
  ),
];

export const mswDcApiHandlers = [
  // **********************
  // DC API
  // **********************
  // POST product
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products`,
    postProduct,
  ),
  // PUT product
  rest.put(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products/:productId`,
    postProduct,
  ),
  // GET product
  rest.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products/:productId`,
    getProductHandler,
  ),
  // GET product releases
  rest.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/releases`,
    getProductReleasesHandler,
  ),
  // GET products list in project
  rest.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products`,
    getAllProductsInProjectHandler,
  ),
  // DELETE product
  rest.delete(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products/:productId`,
    deleteProductTemplateHandler,
  ),
  // POST variant
  rest.post(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants`,
    postVariantHandler,
  ),
  // GET variant
  rest.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants/:variantId`,
    getVariantHandler,
  ),
  // Get variants list
  rest.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants`,
    getVariantsByProductHandler,
  ),
  // GET variant log
  rest.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants/:variantId/logs`,
    getVariantLog,
  ),
  // GET variant output
  rest.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants/:variantId/outputs`,
    getVariantOutputsHandler,
  ),
  // PUT product user config
  rest.put(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/userconfigs/projects/:projectId/products/:productId`,
    putProductUserConfigurationHandler,
  ),
  // Download Url
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:tenancyId/data/downloadurl`,
    getDownloadUrlHandler,
  ),
  // Upload Url
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/data/uploadurls`,
    postUploadUrls,
  ),
  // Complete Upload
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/data/completeupload`,
    postCompleteUpload,
  ),
];

export const mswMiscellaneousWebHandlers = [
  // **********************
  // OTHERS
  // **********************
  // GET metadata
  rest.get(`${ServiceConfigMap[ServiceTypes.MID_ARTIFACTS][reactAppEnvironment].api}/metadata.json`, getMSIMetadata),

  // Local SVG and PNG
  rest.get('http://localhost:3000/static/media/filing_cabinet_grey.*.svg', bypassRequest),
  rest.put('https://s3.aws.com/path/to/thumbnail.png', putUploadUrl),
  // Rules
  rest.get('https://s3.aws.com/path/to/rules.json', getRulesObject),
  rest.get('https://s3.aws.com/path/to/rulesWithError.json', getRulesObjectWithError),
  rest.all('https://api2.amplitude.com/*', amplitudeHandler),
]; // block outgoing http calls by default

export const mswUnhandledPathHandler = [
  rest.all('*', (req, res, ctx) => {
    console.error(`Please add request handler for ${req.url.toString()}`);
    return res(ctx.status(500), ctx.json({ error: 'Please add request handler.' }));
  }),
];
