import React from 'react';
import isUndefined from 'lodash/isUndefined';
import {
  NameAndReleaseWrapper,
  NameTitle,
  ProductReleasesDescription,
  LockTooltip,
  ProductReleaseContainer,
  ProductReleaseTypographyWrapper,
} from './NameAndRelease.styles';
import { ProductRelease, ReleaseStatus } from '@adsk/offsite-dc-sdk';
import IconButton from '@mui/material/IconButton';
import Lock from '@mui/icons-material/Lock';
import text from '../revit-components.text.json';
import { nameAndRelease } from '../../global/dataTestIds';
import { ReleaseStatusWithColorBar } from '../../components/ReleaseStatusWithColorBar/ReleaseStatusWithColorBar';

interface NameAndReleaseProps {
  currentProductRelease: ProductRelease;
  productReleasesListLength: number | undefined;
}

const NameAndRelease: React.FC<NameAndReleaseProps> = ({ currentProductRelease, productReleasesListLength }) => (
  <NameAndReleaseWrapper>
    <NameTitle data-testid={nameAndRelease.releaseName} variant="h2">
      {currentProductRelease.name}
    </NameTitle>
    <ProductReleaseContainer data-testid={nameAndRelease.releasesAndStatus}>
      <ProductReleaseTypographyWrapper>
        {!isUndefined(productReleasesListLength) && (
          <ProductReleasesDescription className="mid-status-secondary">
            {`${currentProductRelease.release} of ${productReleasesListLength}`}
          </ProductReleasesDescription>
        )}
        <ReleaseStatusWithColorBar status={currentProductRelease.status} useSecondaryColor />
      </ProductReleaseTypographyWrapper>
      {currentProductRelease.status === ReleaseStatus.OBSOLETE && (
        <LockTooltip title={text.valuesOfObsoleteReleasesAreReadOnlyTooltip}>
          <IconButton>
            <Lock fontSize="small" />
          </IconButton>
        </LockTooltip>
      )}
    </ProductReleaseContainer>
  </NameAndReleaseWrapper>
);

export default NameAndRelease;
