import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const ReleasesSelectionContainer = styled('div')`
  margin-top: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ReleasesSelectionGridItem = styled('div')`
  grid-column-start: 2;
`;

export const ReleasesWrapper = styled('div')`
  overflow: auto;
`;

export const ReleasesSelectionChip = styled(Chip)`
  &.MuiChip-outlined {
    background-color: ${({ theme }) => theme.colors.surface.lightGray['10']};
    border: none;
  }
`;
