import text from '../../mid-react-common.text.json';
import MIDEmptyState, { EMPTY_STATE_ILLUSTRATION_TYPES } from '../EmptyState/MIDEmptyState';

export const UnderMaintenance: React.FC = (): JSX.Element => (
  <MIDEmptyState
    title={text.underMaintenanceTitle}
    description={text.underMaintenanceDescription}
    illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.BUILDING_CONSTRUCTION_GREY}
  />
);

export default UnderMaintenance;
