import { ForgeJsonApiListResponse, RelatedLink, SelfLink } from './dmApi.types';

export const hubTypes = {
  BIM360_OR_ACC: 'hubs:autodesk.bim360:Account',
  FUSION: 'hubs:autodesk.core:Hub',
} as const;

export type HubType = (typeof hubTypes)[keyof typeof hubTypes];

export type HubLinks = {
  links: SelfLink;
  relationships: {
    projects: {
      links: RelatedLink;
    };
    pimCollection?: {
      data: {
        type: string;
        id: string;
      };
    };
  };
};

export type Hub = {
  type: 'hubs';
  id: string;
  attributes: {
    name: string;
    extension: {
      type: HubType;
      version: string;
      schema: {
        href: string;
      };
      data: {};
    };
    region: string;
  };
} & HubLinks;

export type HubListResponse = ForgeJsonApiListResponse<Hub>;
