import { mockHostApi } from 'mid-addin-lib';
import { AddInWindow, GlobalStyle, LDProviderConfigWithAnonymousAnalytics } from 'mid-react-common';
import GlobalStyles from '@mui/material/GlobalStyles';
import { initializeAmplitude, mswDcApiHandlers, mswForgeApiHandlers, mswMiscellaneousWebHandlers } from 'mid-utils';
import { setupWorker, rest } from 'msw';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ampli } from './ampli';

if (import.meta.env.VITE_CYPRESS_BUILD || (import.meta.env.DEV && import.meta.env.VITE_LOCAL_DEVELOPMENT === 'browser')) {
  // TODO: add mswUnhandledPathHandler to handlers list & add unmocked handlers as needed
  // If added now, the app will crash when run in VITE_LOCAL_DEVELOPMENT=browser mode
  // https://jira.autodesk.com/browse/TRADES-5833
  const handlers = [...mswForgeApiHandlers, ...mswDcApiHandlers, ...mswMiscellaneousWebHandlers];
  const mockWorker = setupWorker(...handlers);

  // Make the `worker` and `rest` references available globally for cypress testing
  window.msw = { mockWorker, rest };

  // Starting msw mock worker to intercept FORGE API calls
  mockWorker.start();

  // Mocking HostApi library
  (window as AddInWindow).hostApi = mockHostApi;
}

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering. This will ensure that the
// <style> tag generated would not recalculate on each render.
const globalStyles = <GlobalStyles styles={GlobalStyle} />;

(async () => {
  initializeAmplitude.bind(ampli)();
  const LDProvider = await asyncWithLDProvider(LDProviderConfigWithAnonymousAnalytics);

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      {globalStyles}
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );
})();

reportWebVitals();
