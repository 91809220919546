import { DropdownItem } from '../Dropdown/Dropdown.types';
import { removeDoubleQuotes } from 'mid-utils';

export interface UseMultiValueListArgs {
  items: string[] | number[];
  onSelectionChange: (newValue: string | number) => void;
  value: string | number | undefined;
}
export interface UseMultiValueListState {
  selectedDropdownItem: DropdownItem | null;
  dropdownItems: DropdownItem[];
  handleOnSelect: (item: { selectedItem?: DropdownItem | null }) => void;
}

const useMultiValueList = ({ items, value, onSelectionChange }: UseMultiValueListArgs): UseMultiValueListState => {
  const dropdownItems: DropdownItem[] = items.map((item) => ({
    // remove quotes for UI
    value: typeof item === 'number' ? item : removeDoubleQuotes(item),
    label: typeof item === 'number' ? item : removeDoubleQuotes(item),
  }));
  const initialLocalSelectedItem = dropdownItems.length > 0 ? dropdownItems[0] : null;
  const selectedDropdownItem =
    dropdownItems.find((dropdownItem) => {
      if (typeof dropdownItem.value === 'string') {
        const valueWithoutDoubleQuotes = removeDoubleQuotes(dropdownItem.value);
        return removeDoubleQuotes((value || '').toString()) === valueWithoutDoubleQuotes;
      }
      return value === dropdownItem.value;
    }) || initialLocalSelectedItem;

  const handleOnSelect = (item: { selectedItem?: DropdownItem | null }): void => {
    if (item.selectedItem) {
      onSelectionChange(item.selectedItem.value);
    } else {
      throw new Error('Invalid selectedItem found.');
    }
  };

  return {
    selectedDropdownItem,
    handleOnSelect,
    dropdownItems,
  };
};

export default useMultiValueList;
