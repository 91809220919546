// TODO: Find a better place for it. Need to please the offiste-logger-client
if (!window.process) {
  window.process = {
    env: import.meta.env,
  };
}
import { createOffsiteLogger, OffsiteLogger } from '@adsk/offsite-logger-client';

const midLogger: OffsiteLogger = createOffsiteLogger({
  service: 'mid-addins',
  moniker: import.meta.env.VITE_MONIKER || '',
});

export default midLogger;
