import React, { useState } from 'react';

interface useProductDetailsReturn {
  tabValue: number;
  handleTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
}

export const useProductDetailsTabs = (): useProductDetailsReturn => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return {
    tabValue,
    handleTabChange,
  };
};
