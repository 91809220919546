import { styled } from '@mui/material/styles';

export const ConfigureScreenWrapper = styled('div')`
  display: flex;
  overflow: auto;
  height: calc(100vh - ${({ theme }) => `${theme.var.headerHeight + theme.var.borderHeight}px`});
`;

export const ConfigureProductCustomizationContainer = styled('div')`
  min-width: calc(
    ${({ theme }) => `${theme.var.wideInputControl}px`} + ${({ theme }) => `${theme.var.paddingBase * 6}px`} +
      ${({ theme }) => `${theme.var.paddingBase * 4}px`}
  );
  width: ${({ theme }) => theme.var.wideInputControl + theme.var.paddingBase * 3}px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ConfigureScrollableContainer = styled('div')`
  flex-grow: 1;
  overflow: auto;
`;

export const ConfigurePreviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const ConfigureProductCustomizationFormWrapper = styled('div')`
  flex-grow: 1;
  overflow: auto;
`;

export const ScrollableContainer = styled('div')`
  flex-grow: 1;
  overflow: auto;
`;
