import Typography from '@mui/material/Typography';
import React from 'react';
import { summaryTableTestIds } from '../../global/dataTestIds';
import { SummaryTableWrapper } from './SummaryTable.styles';

export interface SummaryTableRow {
  title: string;
  value: string | JSX.Element;
}

interface SummaryTableData {
  data: SummaryTableRow[];
  removeBorder?: boolean;
  removeBackground?: boolean;
  addRowsBorder?: boolean;
}

const SummaryTableRowItem: React.FC<SummaryTableRow> = ({ title, value }): JSX.Element => (
  <>
    <td>
      <Typography>
        <strong>{title}</strong>
      </Typography>
    </td>
    <td>{value}</td>
  </>
);

const SummaryTable: React.FC<SummaryTableData> = ({ data, removeBorder, removeBackground, addRowsBorder }): JSX.Element => (
  <SummaryTableWrapper data-testid={summaryTableTestIds.previewTable} className={removeBorder ? '' : 'mid-border-shadow'}>
    <tbody>
      {data.map((row, index) => (
        <tr
          key={`${row.title}-${index}`}
          className={`${removeBackground ? '' : 'mid-bg-zebra'} ${addRowsBorder ? 'mid-border-shadow' : ''}`}
        >
          <SummaryTableRowItem title={row.title} value={row.value} />
        </tr>
      ))}
    </tbody>
  </SummaryTableWrapper>
);

export default SummaryTable;
