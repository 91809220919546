export enum PollState {
  INDETERMINATE = 'INDETERMINATE',
  READY = 'READY',
  POLLING = 'POLLING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export interface UsePollingState<DataType> {
  data: DataType | null;
  pollState: PollState;
  startPolling: () => void;
  resetPolling: () => void;
  cancelPolling: () => void;
}

export interface PollingConfig {
  interval?: number;
}
