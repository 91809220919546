import { isVariantRfaOutput } from 'mid-utils';
import { LogLevel } from '../interfaces/log';
import browserApiService from '../services/browserApiService';
import { logToFile } from './tools';
import { Variant, VariantRfaOutput, OutputType } from '@adsk/offsite-dc-sdk';
import { getSignedUrlFromDownload } from './cloudStorage';

export const insertRFA = async (variant: Variant, representation?: string): Promise<void> => {
  logToFile('Start insertRFA', LogLevel.Info);

  const { outputs, variantId, tenancyId, contentId, release, inputs } = variant;

  const rfaOutput = outputs.find((output): output is VariantRfaOutput =>
    representation ? output.type === OutputType.RFA && output.modelState === representation : isVariantRfaOutput(output),
  );

  logToFile(`rfaOutput: ${JSON.stringify(rfaOutput)}`, LogLevel.Info);
  // throw error if variant doesn't have a finished rfa output
  if (!rfaOutput) {
    throw new Error(`Variant doesn't contain a RFA output.`);
  }

  if (!rfaOutput.urn) {
    throw new Error(`RFA output of variant doesn't contain an object key.`);
  }

  if (!rfaOutput.modelState) {
    throw new Error(`RFA output of variant doesn't contain a model state.`);
  }

  if (!rfaOutput.category) {
    throw new Error(`RFA output of variant doesn't contain a category.`);
  }

  // retrieve a short-lived signed URL for download the file
  const sourceLocation =
    rfaOutput.urn.indexOf(':\\') > 0 ? rfaOutput.urn : await getSignedUrlFromDownload(tenancyId, rfaOutput.urn);
  logToFile(`Get source location, ${sourceLocation}`, LogLevel.Info);
  const engineVersion = variant.context.engine.version;
  logToFile('End insertRFA, call hostApi insertRFA', LogLevel.Info);

  // Encode input parameters
  const encodedInputs = window.btoa(JSON.stringify(inputs));

  return browserApiService.insertRFA(
    tenancyId,
    contentId,
    variantId,
    sourceLocation,
    rfaOutput.family,
    rfaOutput.category,
    engineVersion,
    release,
    rfaOutput.modelState,
    encodedInputs,
  );
};
