import { ENVIRONMENT } from 'mid-types';

export enum ApiPathTypes {
  DM_PROJECTS_PATH = 'DM_PROJECTS_PATH',
}

export const ApiPaths = {
  BIM360_API_PATH: 'bim360/admin/v1',
  ACC_API_PATH: 'construction/admin/v1',
  DM_API_PATH: 'dm/v1',
  DM_STG_API_PATH: 'dm-staging/v1',
  PROJECTS: 'projects',
  ACCOUNTS: 'accounts',
  MODEL_DERIVATIVE_PATH: 'modelderivative/v2/designdata',
  DOCUMENT_THUMBNAIL_PATH: 'derivativeservice/v2/thumbnails',
  IDENTITY_API_PATH: 'identity/v1',
  USERPROFILE_API_PATH: 'userprofile/v1',
  DM_V2_PROJECT_PATH: 'project/v1',
  DM_V2_DATA_PATH: 'data/v1',
  BIM360_V2_API_PATH: 'bim360/admin/v2',
};

export const ApiPathsConfigMap = {
  [ApiPathTypes.DM_PROJECTS_PATH]: {
    [ENVIRONMENT.PRD]: {
      path: `${ApiPaths.DM_API_PATH}/${ApiPaths.PROJECTS}`,
    },
    [ENVIRONMENT.STG]: {
      path: `${ApiPaths.DM_STG_API_PATH}/${ApiPaths.PROJECTS}`,
    },
    [ENVIRONMENT.DEV]: {
      path: `${ApiPaths.DM_STG_API_PATH}/${ApiPaths.PROJECTS}`,
    },
  },
};
