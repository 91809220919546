import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { TextFieldWrapper } from '../ProductCustomization.styles';
import { addDoubleQuotes, removeDoubleQuotes } from 'mid-utils';

export interface TextParameterCustomizationProps {
  value: string | undefined;
  readOnly?: boolean;
  label: string;
  error?: boolean;
  onTextValueChange: (newValue: string) => Promise<void>;
  doubleQuotesControl?: boolean;
}

const TextParameterCustomization: React.FC<TextParameterCustomizationProps> = ({
  value,
  readOnly,
  label,
  error,
  onTextValueChange,
  // this property controls the transformation of a string value from double-quoted version to a regular string when
  // rendering it on the UI and back from the regular to the double-quoted one when persisting data upon value change
  // this is an Inventor-specific behavior
  // example: "Maple" -> "\"Maple\"" or 'Maple' -> '"Maple"'
  doubleQuotesControl,
}) => {
  const [localValue, setLocalValue] = React.useState<string>();
  const [valueUpdated, setValueUpdated] = useState(false);

  // make sure that the updated value from the code runner is set to the local value
  if (valueUpdated) {
    if (value !== localValue) {
      setLocalValue(value);
    }
    setValueUpdated(false);
  }

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const updateLocalValue = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const _updateValue = async (value = '') => {
    let newValue = value;

    if (doubleQuotesControl) {
      newValue = addDoubleQuotes(newValue);
    }

    await onTextValueChange(newValue);

    setValueUpdated(true);
  };

  const updateValue = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
    _updateValue(e.target.value);
  };

  const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    // trigger code runner when Enter is pressed
    if (e.key === 'Enter') {
      _updateValue(localValue);
    }
  };

  return (
    <TextFieldWrapper
      size="small"
      label={label}
      variant="outlined"
      value={doubleQuotesControl ? removeDoubleQuotes(localValue || '') : localValue}
      error={error}
      onChange={updateLocalValue}
      onBlur={updateValue}
      onKeyDown={onKeyDown}
      disabled={readOnly}
    />
  );
};

export default TextParameterCustomization;
