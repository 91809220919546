import React from 'react';
import { DCInputUIExtension } from 'mid-types';
import { DCInputIPropertyType } from '@adsk/offsite-dc-sdk';
import { TextFieldWrapper } from '../ProductCustomization.styles';

interface IPropertyInputProps {
  input: DCInputUIExtension<DCInputIPropertyType>;
  label: string;
}

export const IPropertyInput: React.FC<IPropertyInputProps> = ({ input, label }) => (
  <TextFieldWrapper size="small" label={label} value={input.value} disabled />
);
