import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const ReleaseStatusColorBarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-top-left-radius: ${({ theme }) => theme.var.releaseStatusColorBarBorderRadius}px;
  border-bottom-left-radius: ${({ theme }) => theme.var.releaseStatusColorBarBorderRadius}px;
  padding-left: ${({ theme }) => theme.var.paddingBase}px;
`;

export const ReleaseStatusState = styled(Typography)`
  ::first-letter {
    text-transform: capitalize;
  }
`;
