import CircularProgress from '@mui/material/CircularProgress';
import text from '../revit-components.text.json';
import { useVariantThumbnail } from './useVariantThumbnail';
import { useTheme } from '@mui/material/styles';
import { PreviewImage, PreviewInProgressMessageWrap, PreviewLoader } from '../../global/styles/Common.styles';
import MIDEmptyState, { EMPTY_STATE_ILLUSTRATION_TYPES } from '../../components/EmptyState/MIDEmptyState';
import { variantThumbnail } from '../../tests/helpers/dataTestIds';

interface VariantThumbnailProps {
  tenancyId?: string;
  thumbnail?: string;
  isLoading?: boolean;
  alternateMessage?: string;
}

const { thumbnailLoaderContainer, thumbnailContainer } = variantThumbnail;

const VariantThumbnail: React.FC<VariantThumbnailProps> = ({ tenancyId, thumbnail, isLoading, alternateMessage }) => {
  const { shouldThumbnailBoxShowLoader, thumbnailInBase64, thumbnailError } = useVariantThumbnail({
    tenancyId,
    thumbnail,
    isLoading,
  });
  const theme = useTheme();
  return (
    <>
      {shouldThumbnailBoxShowLoader ? (
        <PreviewLoader data-testid={thumbnailLoaderContainer}>
          <CircularProgress size={theme.var.circularProgressSize} thickness={theme.var.circularProgressThickness} />
          <PreviewInProgressMessageWrap>{alternateMessage || text.updateInProgressMessage}</PreviewInProgressMessageWrap>
        </PreviewLoader>
      ) : thumbnailInBase64 ? (
        <PreviewImage
          data-testid={thumbnailContainer}
          src={`data:image/jpeg;base64,${thumbnailInBase64}`}
          alt={text.previewImageAltText}
        />
      ) : (
        <MIDEmptyState
          illustrationType={
            thumbnailError ? EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR : EMPTY_STATE_ILLUSTRATION_TYPES.PAGES_PHOTO
          }
          title={thumbnailError ? text.previewNotAvailable : ''}
        />
      )}
    </>
  );
};

export default VariantThumbnail;
