import { styled, css } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const SpanBlockText = styled('span')`
  display: block;
`;

export const DialogWrapper = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'isAddin',
})<{ isAddin?: boolean }>`
  ${({ isAddin }) =>
    isAddin &&
    css(`
      user-select: none;
    `)}
`;
