import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  url?: string;
  objectKey?: string;
  error?: Error;
};

export class FileUrlError extends CustomError {
  errorCode = ErrorCode.FileUrlError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
