import { Vendors } from '@adsk/offsite-dc-sdk';
import { HubType, hubTypes } from 'mid-types';

// In APS Data Management v2 API, all BIM360 and ACC IDs are prefixed with b., and Fusion ID is prefixed with a.
const BIM360_OR_ACC_PREFIX = 'b.';
const FUSION_PREFIX = 'a.';
const PREFIX_LENGTH = 2;

export const normalizeIdForDataManagementV2 = (id: string, vendor?: Vendors | HubType): string => {
  if (id.startsWith(BIM360_OR_ACC_PREFIX) || id.startsWith(FUSION_PREFIX)) {
    return id;
  }

  return vendor === Vendors.FUSIONTEAM || vendor === hubTypes.FUSION
    ? `${FUSION_PREFIX}${id}`
    : `${BIM360_OR_ACC_PREFIX}${id}`;
};

export const stripIdPrefix = (id: string): string => {
  if (id.startsWith(BIM360_OR_ACC_PREFIX) || id.startsWith(FUSION_PREFIX)) {
    return id.slice(PREFIX_LENGTH);
  }

  return id;
};
