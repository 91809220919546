import React from 'react';
import { DCInputUIExtension } from 'mid-types';
import { DCInput, DCInputBooleanType } from '@adsk/offsite-dc-sdk';
import { SwitchButtonGroup } from '../../SwitchButtonGroup/SwitchButtonGroup';

interface BooleanInputProps {
  input: DCInputUIExtension<DCInputBooleanType>;
  error?: boolean;
  label: string;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
}

export const BooleanInput: React.FC<BooleanInputProps> = ({ input, error, label, handleInputUpdate }) => {
  const handleSwitchButtonSelectionChange = (newValue: boolean) => handleInputUpdate({ ...input, value: newValue });
  return (
    <SwitchButtonGroup
      name={input.name}
      readOnly={input.readOnly}
      label={label}
      value={input.value}
      error={error}
      trueLabel={input.trueLabel}
      falseLabel={input.falseLabel}
      onSwitchButtonSelectionChange={handleSwitchButtonSelectionChange}
    />
  );
};
