import { OutputType, OutputTypes, DCOutput, PostVariantOutput, OutputStatus } from '@adsk/offsite-dc-sdk';

const BOMAggregated = 'BOMAGGREGATED' as const;
export type BOMAGGREGATED = typeof BOMAggregated;
export type DCOutputAggregatedBom = {
  type: BOMAGGREGATED;
  options: {
    modelStates: string[];
  };
};
export const OutputTypeWithVirtualTypes = { ...OutputType, BOMAGGREGATED: BOMAggregated };
export type PostVariantOutputAggregatedBom = {
  type: BOMAGGREGATED;
  modelState: string;
};
export type DCOutputWithVirtualTypes = DCOutput | DCOutputAggregatedBom;
export type OutputTypesWithVirtualTypes = OutputTypes | BOMAGGREGATED;
export const VirtualOutputTypes = [BOMAggregated];
export type PostVariantOutputWithVirtualTypes = PostVariantOutput | PostVariantOutputAggregatedBom;
const CancelledOutputStatus = 'CANCELLED';
export type CANCELLED = typeof CancelledOutputStatus;
export const VirtualOutputStatus = { ...OutputStatus, CANCELLED: CancelledOutputStatus };
