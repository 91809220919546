import Typography from '@mui/material/Typography';
import React from 'react';
import { ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import text from '../../mid-react-common.text.json';
import SummaryTable, { SummaryTableRow } from '../SummaryTable/SummaryTable';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ProductDetailsProps {
  title?: string;
  productCategory: string;
  productFamily: string;
  representations: string[];
  releaseStatus?: ReleaseStatusValue;
  releaseNumber?: number;
}

const PRODUCT_REPRESENTATION_SEPARATOR = '; ';

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  title,
  productCategory,
  productFamily,
  representations,
  releaseStatus,
  releaseNumber,
}): JSX.Element => {
  const { enableReleasesSelection } = useFlags();
  const productDetailsData: SummaryTableRow[] = [
    {
      title: text.revitCategoryProductDetails,
      value: productCategory,
    },
    {
      title: text.revitFamilyProductDetails,
      value: productFamily,
    },
  ];

  if (representations.length) {
    productDetailsData.push({
      title: text.revitRepresentation,
      value: representations.join(PRODUCT_REPRESENTATION_SEPARATOR),
    });
  }

  if (enableReleasesSelection) {
    productDetailsData.push(
      {
        title: text.revitReleaseStatus,
        value: releaseStatus || '',
      },
      {
        title: text.revitReleaseNumber,
        value: releaseNumber?.toString() || '',
      },
    );
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <SummaryTable data={productDetailsData} removeBackground addRowsBorder />
    </>
  );
};
