import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { PropsWithChildren } from 'react';
import { DialogWrapper } from './PrintMessageDialog.styles';

interface PrintMessageDialogProps {
  isMessageDialogOpen: boolean;
  closeMessageDialog: () => void;
  isAddin?: boolean;
}

const PrintMessageDialog: React.FC<PropsWithChildren<PrintMessageDialogProps>> = ({
  isMessageDialogOpen,
  closeMessageDialog,
  isAddin,
  children,
}) => (
  <DialogWrapper open={isMessageDialogOpen} onClose={closeMessageDialog} isAddin={isAddin}>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeMessageDialog} autoFocus>
        Close
      </Button>
    </DialogActions>
  </DialogWrapper>
);

export default PrintMessageDialog;
