import { useEffect, useCallback, useState, useMemo } from 'react';
import { getDcApiServiceInstance } from 'mid-api-services';
import { ProductReleaseError, logError } from 'mid-utils';
import { OutputType, OutputStatus, Variant, ProductRelease } from '@adsk/offsite-dc-sdk';
import { useAsyncPollingWithArgs } from '../../global/hooks/http/hooks';
import { useCancellablePromise } from '../../global/hooks/misc';
import { areAllVariantOutputsFinished } from '../../global/hooks/variant/useVariantPolling';
import { StateSetter } from '../../global/types';
import { ShowNotificationProps } from '../../context/NotificationStore/notificationStore';
import { NOTIFICATION_STATUSES } from '../../context/NotificationStore/notificationStore.types';
import text from '../revit-components.text.json';

interface UseFetchingListOfVariantsProps {
  currentProductRelease: ProductRelease | undefined;
  cachedVariantsList: Variant[];
  reFetchCachedVariants: boolean;
  postedVariantIdsList: string[];
  setReFetchCachedVariants: StateSetter<boolean>;
  showNotification: (props: ShowNotificationProps) => void;
}

interface UseFetchingListOfVariantsState {
  filteredVariantsListByCurrentRelease: Variant[] | undefined;
}

// Check if every cached variant has outputs with status SUCCESS or FAILED (finished statuses)
const areAllCachedVariantsGenerated = (variants: Variant[] | undefined) => {
  if (!variants) {
    return true;
  }
  return variants?.every((variant) => areAllVariantOutputsFinished(variant.outputs));
};

const useFetchingListOfVariants = ({
  currentProductRelease,
  cachedVariantsList,
  reFetchCachedVariants,
  postedVariantIdsList,
  setReFetchCachedVariants,
  showNotification,
}: UseFetchingListOfVariantsProps): UseFetchingListOfVariantsState => {
  const [shouldPollingStart, setShouldPollingStart] = useState(true);
  const cancellablePromise = useCancellablePromise();
  const [fetchedVariantsList, setFetchedVariantsList] = useState<Variant[] | undefined>(undefined);

  const fetchListOfVariants = useCallback(async () => {
    if (!currentProductRelease?.contentId) {
      throw new ProductReleaseError(text.listVariantsMissingSelectedProductRelease, {
        currentProductRelease,
      });
    }

    try {
      const variants = await getDcApiServiceInstance().getVariantsList(
        currentProductRelease.tenancyId,
        currentProductRelease.contentId,
      );
      setFetchedVariantsList(variants);

      return variants;
    } catch (err) {
      logError(err);
      showNotification({
        message: text.failedToLoadCachedVariants,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      setFetchedVariantsList(undefined);
    } finally {
      setReFetchCachedVariants(false);
    }
  }, [currentProductRelease, setReFetchCachedVariants, showNotification]);

  const filteredVariantsListByCurrentRelease = useMemo(() => {
    if (!fetchedVariantsList || !currentProductRelease) {
      return;
    }
    return fetchedVariantsList
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .filter(
        (variant) =>
          variant.release === currentProductRelease.release &&
          // if variant has been posted within this session, show it, even if it failed
          (postedVariantIdsList.includes(variant.variantId) ||
            variant.outputs.some(
              (output) =>
                (output.type === OutputType.RFA && output.status === OutputStatus.SUCCESS) ||
                (output.type === OutputType.RFA && output.status === OutputStatus.PENDING),
            )),
      );
  }, [fetchedVariantsList, currentProductRelease, postedVariantIdsList]);

  const { startPolling } = useAsyncPollingWithArgs<Variant[] | undefined>(
    fetchListOfVariants,
    (variants: Variant[] | undefined) => !areAllCachedVariantsGenerated(variants),
  );

  useEffect(() => {
    if (reFetchCachedVariants) {
      cancellablePromise(fetchListOfVariants());
    }
  }, [reFetchCachedVariants, cancellablePromise, fetchListOfVariants]);

  // Check if we need to start polling
  useEffect(() => {
    if (shouldPollingStart && !areAllCachedVariantsGenerated(cachedVariantsList)) {
      setShouldPollingStart(false);
      startPolling();
    }
  }, [startPolling, shouldPollingStart, cachedVariantsList]);

  return { filteredVariantsListByCurrentRelease };
};

export default useFetchingListOfVariants;
