import { Container } from 'inversify';
import { Environment, AuthHandler } from 'mid-types';
import { InversifyTypes } from './inversifyTypes';
import { DcApiService } from '../dcApi.service';
import { ForgeApiService } from '../forgeApiService';
import { mockForgeApiService } from '../forgeApiService/mock';

export const inversifyContainer = new Container();

//#region Bind dependencies

inversifyContainer.bind<DcApiService>(InversifyTypes.DcApiService).to(DcApiService).inSingletonScope();
inversifyContainer.bind<ForgeApiService>(InversifyTypes.ForgeApiService).to(ForgeApiService).inSingletonScope();

//#region functions to register dependencies from outside mid-api-services workspace

export const registerDcApiBaseURL = (baseURL: string): void => {
  inversifyContainer.bind<string>(InversifyTypes.DcApiBaseURL).toConstantValue(baseURL);
};

export const registerEnv = (env: Environment): void => {
  inversifyContainer.bind<string>(InversifyTypes.Env).toConstantValue(env);
};

export const registerForgeApiBaseURL = (baseURL: string): void => {
  inversifyContainer.bind<string>(InversifyTypes.ForgeApiBaseURL).toConstantValue(baseURL);
};

export const registerAuthHandler = (authHandler: AuthHandler): void => {
  inversifyContainer.bind<AuthHandler>(InversifyTypes.AuthHandler).toFunction(authHandler);
};

//#region Get service instances to expose outside of mid-api-services workspace

export const getDcApiServiceInstance = (): DcApiService => inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
export const getForgeApiServiceInstance = (): ForgeApiService =>
  inversifyContainer.get<ForgeApiService>(InversifyTypes.ForgeApiService);

export const getMockedDCApiServiceInstance = (): DcApiService => {
  const mockedDCApiService = new DcApiService('', () => Promise.resolve('mock-token'), 'dev');
  inversifyContainer.rebind<DcApiService>(InversifyTypes.DcApiService).toConstantValue(mockedDCApiService);

  return mockedDCApiService;
};

export const getMockedForgeApiServiceInstance = (): ForgeApiService => {
  inversifyContainer
    .rebind<ForgeApiService>(InversifyTypes.ForgeApiService)
    .toConstantValue(mockForgeApiService as ForgeApiService);

  return mockForgeApiService as ForgeApiService;
};

//#endregion

//#endregion

//#endregion
