import { DynamicContent, ProductRelease, OutputTypes, OutputType, Variant } from '@adsk/offsite-dc-sdk';

export type DCInputUIExtension<T> = T & {
  error?: boolean;
  message?: string;
};
export interface BatchFetchProductsResult {
  results: DynamicContent[];
  errors: PromiseRejectedResult[];
}

export interface PaginationMeta {
  limit: number;
  nextUrl?: string;
}

export interface VariantsResponse {
  pagination: PaginationMeta;
  results: Variant[];
}

export const IDW_FILE_TYPE = 'idw';
export const DWG_FILE_TYPE = 'dwg';
export const PDF_FILE_TYPE = 'pdf';

export const drawingTemplateFileTypes = [IDW_FILE_TYPE, DWG_FILE_TYPE];

export const drawingOutputTypes: { [key: string]: OutputTypes } = {
  [IDW_FILE_TYPE]: OutputType.IDW,
  [DWG_FILE_TYPE]: OutputType.DWG,
  [PDF_FILE_TYPE]: OutputType.PDF,
};

export interface ProductReleasesQueryParameters {
  productId: string;
  projectId: string;
}
export interface GetAllProductReleasesResult {
  results: ProductRelease[];
  errors: PromiseRejectedResult[];
}
