import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Accordion, AccordionDetails } from '@mui/material';

export const ProductCustomizationFormWrapper = styled('div')`
  flex-grow: 1;
  overflow: auto;
`;

export const ParameterCustomizationWrapper = styled('div')`
  display: flex;
`;

export const TextFieldWrapper = styled(TextField)`
  width: ${({ theme }) => `${theme.var.wideInputControl}px`};
`;

export const FormLoadingSpinner = styled(CircularProgress)`
  align-self: center;
`;

export const ConfigureProductCustomizationHeader = styled(Typography)`
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
`;

export const AccordionTitle = styled(AccordionSummary)`
  flex-direction: row-reverse;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  gap: ${({ theme }) => `${theme.var.paddingBase}px`};
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }
  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
  }
`;

export const AccordionContent = styled(AccordionDetails)`
  padding: 0;
`;

export const AccordionFullWidth = styled(Accordion)`
  width: 100%;
`;
