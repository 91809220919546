import { mockFolder1, mockFolder1a, mockFolder1b, mockFolder2, mockFolder2a } from './mockFolders';
import {
  mockJavascriptRules1,
  mockJavascriptRules2,
  mockProductWithDynamicContentSDK,
  mockProjectIdWithProducts,
  ruleObjectKeyWError,
} from './mockProducts';
import { ProductRelease, DCInputNumericType, OutputType, ReleaseStatus, InputType } from '@adsk/offsite-dc-sdk';
import { mockProject } from './mockProjects';

export const mockDCReleaseResponse: ProductRelease = {
  schemaVersion: 1,
  status: ReleaseStatus.ACTIVE,
  name: 'AD - Balcony Assembly - Inventor 2022',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rules: {},
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  tenancyId: mockProjectIdWithProducts,
  contentId: 'cd4ada75-d634-4051-ae99-d63978fa2a69',
  release: 2,
  createdAt: '',
  updatedAt: '',
};

export const mockDCReleaseResponse2: ProductRelease = {
  schemaVersion: 1,
  status: ReleaseStatus.DEFAULT,
  name: 'AD - Balcony Assembly - Inventor 2022',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rules: {},
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  tenancyId: mockProjectIdWithProducts,
  contentId: 'cd4ada75-d634-4051-ae99-d63978fa2a69',
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockObsoleteProductRelease: ProductRelease = {
  ...mockProductWithDynamicContentSDK,
  status: ReleaseStatus.OBSOLETE,
};

export const mockProductRelease2: ProductRelease = {
  ...mockProductWithDynamicContentSDK,
  release: 2,
  status: ReleaseStatus.ACTIVE,
};

export const mockProductRelease3: ProductRelease = {
  ...mockProductWithDynamicContentSDK,
  release: 3,
  rules: {
    currentRule: {
      code: mockJavascriptRules1,
    },
  },
  status: ReleaseStatus.DEFAULT,
};

export const mockProductReleaseWithOneNumericValueAndRules: ProductRelease = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c32254',
  thumbnail: '11111111cc9c44a4ad79b509e1c32254',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e4?region=US',
  inputs: [
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 30,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithOneNumericValueAndRules',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: `
      parameters['Width'].min = 32;
      parameters['Width'].max = 48;
      parameters['Width'].increment = 2;
      `,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  createdAt: '',
  updatedAt: '',
  release: 4,
  status: ReleaseStatus.DEFAULT,
};

export const mockProductReleaseWithRulesError: ProductRelease = {
  contentId: '777777777-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRulesError',
  outputs: [
    {
      options: {
        category: 'Balcony 5',
        family: 'Balcony 5',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rulesKey: ruleObjectKeyWError,
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 5,
  status: ReleaseStatus.DEFAULT,
  createdAt: '',
  updatedAt: '',
};

export const mockMIDShelvingUnitProductRelease: ProductRelease = {
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  name: 'MID Shelving Unit',
  thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
  rules: {
    currentRule: {
      ruleLabel: 'currentRule',
      code: "parameters['Height'].values = [30, 42, 54, 66];parameters['Material'].values = ['Laminated Veneer Lumber', 'Particleboard', 'Ash', 'Birch', 'Mahogany', 'Maple', 'Oak, Red', 'Pine', 'Plywood, Finish', 'Spruce', 'Walnut'];parameters['Shelves_Qty'].values = [1, 2, 3, 4];if ((parameters['Height'].value) == 30) {\n  parameters['Shelves_Qty'].values = [1];} else if ((parameters['Height'].value) == 42) {\n  parameters['Shelves_Qty'].values = [1, 2];} else if ((parameters['Height'].value) == 54) {\n  parameters['Shelves_Qty'].values = [1, 2, 3];} else if ((parameters['Height'].value) == 66) {\n  parameters['Shelves_Qty'].values = [1, 2, 3, 4];parameters['Material'].values = ['Oak, Red', 'Maple', 'Mahogany', 'Birch'];}\n",
    },
  },
  schemaVersion: 1,
  codeBlocksWorkspaceKey: 'mock-key',
  tenancyId: mockProject.id,
  contentId: 'c3e2ea91-6f4d-4462-9436-f8a183c8e510',
  inputs: [
    {
      name: 'Height',
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: 'Height',
      unit: 'in',
      value: 54,
      values: [30, 42, 54, 66],
      allowCustomValue: true,
      onChange: [],
    },
    {
      name: 'Material',
      type: InputType.TEXT,
      visible: true,
      readOnly: false,
      label: 'Material',
      unit: 'Text',
      value: '"Oak, Red"',
      values: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      allowCustomValue: true,
    },
    {
      name: 'Shelves_Qty',
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: 'Shelves_Qty',
      unit: 'ul',
      value: 3,
      values: [1, 2, 3],
      allowCustomValue: true,
      onChange: [],
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['[Primary]'],
        category: 'Furniture',
        family: 'MID Shelving Unit',
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['Primary'],
      },
    },
  ],
  context: {
    projectFile: '\\MID Shelving Unit.ipj',
    topLevelAssembly: 'MID Shelving Unit\\MID Shelving Unit.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      location: 'BIMDOCS',
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
    },
  },
  release: 6,
  status: ReleaseStatus.DEFAULT,
  createdAt: '',
  updatedAt: '',
};

export const mockProductReleaseWithRules2: ProductRelease = {
  tenancyId: mockProjectIdWithProducts,
  contentId: '1231232-cc9c-44a4-ad79-b509e1c3kjkj',
  thumbnail: '22221231212cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 1,
      label: 'Door Width',
      max: 100,
      min: 1,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 34,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      readOnly: true,
      type: InputType.IPROPERTY,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue NUMERIC',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 4,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text3',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRules2',
  outputs: [
    {
      options: {
        modelStates: ['Master'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 1',
        family: 'Balcony 1',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: mockJavascriptRules2,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  release: 1,
  status: ReleaseStatus.DEFAULT,
  createdAt: '',
  updatedAt: '',
};

export const mockProductReleasesList = [mockObsoleteProductRelease, mockProductRelease2, mockProductRelease3];

export const mockProductReleaseNumericInput: DCInputNumericType = {
  increment: 2,
  label: 'Door Width',
  max: 48,
  min: 32,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 34,
  visible: true,
  allowCustomValue: true,
};

export const mockProductReleaseWithSDKType: ProductRelease = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  tenancyId: mockProject.id,
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Door Width - Read Only',
      name: 'WidthReadOnly',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      type: InputType.IPROPERTY,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      value: 2,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      value: 'text2',
    },
    {
      label: 'Door Width',
      name: 'Width',
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
    },
  ],
  name: 'mockProductWithRules3',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony 0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  status: ReleaseStatus.DEFAULT,
  schemaVersion: 1,
  release: 1,
  createdAt: '',
  updatedAt: '',
};
