import React, { useContext } from 'react';
import NavigationContext from '../../context/Navigation/Navigation.context';
import { Screens } from '../../context/Navigation/navigationStore';
import { ProductSelectionScreen } from '../../screens/ProductSelectionScreen/ProductSelectionScreen';
import { ConfigureScreen } from '../../screens/VariantScreens/ConfigureScreen/ConfigureScreen';
import { ProductCustomizationTabs } from '../../context/Data/dataStore';
import SelectScreen from '../../screens/VariantScreens/SelectScreen/SelectScreen';

export const ScreenManager: React.FC = (props) => {
  const { currentScreen, selectedProductCustomizationTab } = useContext(NavigationContext);

  if (currentScreen === Screens.PRODUCT_SELECTION) {
    return <ProductSelectionScreen {...props} />;
  }
  if (currentScreen === Screens.PRODUCT_CUSTOMIZATION) {
    if (selectedProductCustomizationTab === ProductCustomizationTabs.TAB_NEW) {
      return <ConfigureScreen {...props} />;
    }
    return <SelectScreen />;
  }
  return <ProductSelectionScreen {...props} />;
};
