import { getVariant, getVariantOutputs } from 'mid-addin-lib';
import { VirtualOutputStatus } from 'mid-types';
import { useAsyncPollingWithArgs } from '../http/hooks';
import { PollState } from '../http/hooks.types';
import { OutputStatus, Variant, VariantOutput } from '@adsk/offsite-dc-sdk';
import { isVariantThumbnailOutput } from 'mid-utils';

export interface UseVariantPollingState {
  polledVariant: Variant | null;
  pollState: PollState;
  startPollingVariantStatus: () => void;
  resetPollingStatus: () => void;
  cancelPollingStatus: () => void;
}
interface PollVariantArgs {
  tenancyId: string | undefined;
  contentId: string | undefined;
  variantId: string | undefined;
  representation?: string;
  enableMultiValuesBackwardsCompatibility: boolean;
}

const pollVariant = async ({
  tenancyId,
  contentId,
  variantId,
  representation,
  enableMultiValuesBackwardsCompatibility,
}: PollVariantArgs): Promise<Variant | undefined> => {
  if (!tenancyId || !contentId || !variantId) {
    return;
  }
  const variant = await getVariant(tenancyId, contentId, variantId, enableMultiValuesBackwardsCompatibility);
  const outputs = await getVariantOutputs(variant, representation);

  const polled: Variant = { ...variant };
  if (outputs.length) {
    polled.outputs = outputs;
  }

  const thumbnailOutput = polled.outputs.find((o) =>
    representation ? isVariantThumbnailOutput(o) && o.modelState === representation : isVariantThumbnailOutput(o),
  );
  polled.thumbnail = thumbnailOutput?.urn ?? polled.thumbnail;
  return polled;
};

export const areAllVariantOutputsSuccessful = (outputs: VariantOutput[]): boolean =>
  !!outputs.length && outputs.every((output) => output.status === OutputStatus.SUCCESS);

export const areAllVariantOutputsFinished = (outputs: VariantOutput[]): boolean =>
  !!outputs.length &&
  outputs.every((output) => output.status === OutputStatus.SUCCESS || output.status === OutputStatus.FAILED);

export const shouldVariantOutputsPollingContinue = (variant: Variant): boolean =>
  !!variant.outputs.length &&
  !variant.outputs.every((output) =>
    [VirtualOutputStatus.SUCCESS, VirtualOutputStatus.FAILED, VirtualOutputStatus.CANCELLED].includes(output.status),
  );

// The main objective of this hook is to keep polling the
// variant specified and stop when the shouldPollingContinue
// condition is met
export const useVariantPolling = ({
  tenancyId,
  contentId,
  variantId,
  representation,
  enableMultiValuesBackwardsCompatibility,
}: PollVariantArgs): UseVariantPollingState => {
  const {
    data: polledVariant,
    pollState,
    startPolling: startPollingVariantStatus,
    resetPolling: resetPollingStatus,
    cancelPolling: cancelPollingStatus,
  } = useAsyncPollingWithArgs<Variant>(
    () =>
      pollVariant({
        tenancyId,
        contentId,
        variantId,
        representation,
        enableMultiValuesBackwardsCompatibility,
      }),
    shouldVariantOutputsPollingContinue,
  );

  return {
    polledVariant,
    pollState,
    startPollingVariantStatus,
    resetPollingStatus,
    cancelPollingStatus,
  };
};
