import { ErrorCode } from './errorCode';
import { CustomError } from './customError';

export class CacheError extends CustomError {
  errorCode = ErrorCode.CacheError;

  constructor(message: string) {
    super(message);
  }
}
