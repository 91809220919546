import { styled } from '@mui/material/styles';

export const StyledErrorFallback = styled('div')`
  font-family: Artifakt Element, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 20px;
  text-align: center;

  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }

  .error-message {
    color: red;
  }
`;
