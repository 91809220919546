import { styled } from '@mui/material/styles';

export const SummaryTableWrapper = styled('table')`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => `${theme.typography.fontSize}px`};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  border-collapse: collapse;
  width: 100%;

  td {
    text-align: left;
    padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  }

  td:first-of-type {
    width: 40%;
  }
`;
