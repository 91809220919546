import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { styled, css } from '@mui/material/styles';

export const ProductFolderFlexContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 20px;
`;

export const ProductFolderBrowserContainer = styled('div')`
  margin-top: ${({ theme }) => `${theme.var.marginBase * 2}px`};
`;

export const ListItemWrapper = styled(ListItem)`
  cursor: not-allowed;
`;

export const ProductListWrapper = styled(List, { shouldForwardProp: (prop) => prop !== 'maxHeight' })<{
  maxHeight?: number;
}>`
  min-width: ${({ theme }) => theme.var.midTreeMinWidth}px;
  ${({ maxHeight }) =>
    maxHeight &&
    css(`
      max-height: calc(100vh - ${maxHeight}px);
      overflow: auto;
  `)}
`;

export const TreeWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'maxHeight' })<{ maxHeight?: number }>`
  ${({ maxHeight }) =>
    maxHeight &&
    css(`
      max-height: calc(100vh - ${maxHeight}px);
      overflow: auto;
  `)}
`;
