import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import { CSSProperties } from 'react';

export const GlobalStyle = css(`
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  html {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  #root {
    padding: 0;
    margin: 0;
    height: 100%;
  }
`);

export const LeftButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'upperCase',
})<{ upperCase?: boolean }>`
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
  ${(props) =>
    props.upperCase &&
    css(`
      text-transform: uppercase;
    `)}
`;

export const RightButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'upperCase',
})<{ width?: number; height?: number; upperCase?: boolean }>`
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
  ${(props) =>
    props.width &&
    css(`
      width: ${props.width}px;
    `)}
  ${(props) =>
    props.height &&
    css(`
        height: ${props.height}px;
      `)}
  ${(props) =>
    props.upperCase &&
    css(`
      text-transform: uppercase;
    `)}
  white-space: nowrap;
  flex-shrink: 0;
`;

export const CustomTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'upperCase',
})<{ upperCase?: boolean }>`
  ${(props) =>
    props.upperCase &&
    css(`
      text-transform: uppercase;
    `)}
`;

export const MiddleSection = styled('div')`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const MiddleButton = styled(Button)`
  margin: 0 ${({ theme }) => `${theme.var.marginBase / 2}px`};
  white-space: nowrap;
`;

export const TooltipWrapper = styled('span')`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${({ theme }) => `${theme.var.marginBase / 2}px`};
`;

export const FieldSetContainer = styled('fieldset')<{ fullWidth?: boolean }>`
  padding-left: 0;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  padding-right: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const RowItem = styled('div')<{ justifyContent?: CSSProperties['justifyContent'] }>`
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  flex: 1;
  display: flex;
`;

export const FieldsetRow = styled('div')<{ alignItemsProp?: string }>`
  padding: ${({ theme }) =>
    `${theme.var.paddingBase}px 0px ${theme.var.paddingBase}px ${theme.var.productFormHorizontalSpacing}px`};
  display: flex;
  align-items: ${(props) => props.alignItemsProp || 'flex-start'};
`;

export const FieldsetGrid = styled('div')`
  display: grid;

  // Basic Grid 2x2. Should be extended in order to customize that
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;

  column-gap: ${({ theme }) => `${theme.var.marginBase}px`};
  row-gap: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const FieldsetGridLabel = styled(InputLabel)`
  align-self: center;
`;

export const InputFormControl = styled(FormControl)<{ width: string }>`
  width: ${({ width }) => width};
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const SpinnerForButton = styled(CircularProgress)`
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const FlexContainer = styled('div', {
  shouldForwardProp: (prop) => !['alignItems', 'justifyContent', 'gap', 'flexDirection', 'overflow'].includes(String(prop)),
})<{
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  gap?: number;
  flexDirection?: CSSProperties['flexDirection'];
  overflow?: CSSProperties['overflow'];
}>`
  display: flex;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `}
  ${(props) =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `}
  ${(props) =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
`;

export const GridTwoColumns = styled('div')`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.var.midTreeMinWidth}px ${({ theme }) => theme.var.midTreeMinWidth}px;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const SidebarButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase / 2}px`};
`;

export const ContentWrapper = styled('div')`
  margin: 0 ${({ theme }) => `${theme.var.marginBase * 2}px`};
  width: 80%;
  display: block;
`;

export const PreviewWrapper = styled('div')<{ display?: CSSProperties['display'] }>`
  // ability to hide the div without the component re-rendering
  display: ${({ display }) => (display ? display : 'flex')};
  justify-content: center;
  align-items: center;
  height: 40vh;
  min-height: 250px;
  max-height: 300px;
`;

export const PreviewLoader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const PreviewInProgressMessageWrap = styled(Typography)`
  font-size: 1.5em;
  margin: ${({ theme }) => `${theme.var.marginBase}px`} 0;
`;

export const PreviewImage = styled('img')`
  height: 100%;
  width: auto;
`;

export const PreviewImageWidthBased = styled('img')<{ maxWidth?: number }>`
  width: 100%;
  max-width: ${(props) => props.maxWidth}px;
`;

export const EmptyStateImage = styled('img')`
  margin-bottom: 24px;
  height: 100%;
  width: auto;
`;

export const EmptyStateContainer = styled('div')`
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-block: ${({ theme }) => `${theme.var.marginBase * 4}px`};
`;

export const TableEmptyStateWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LocationContainer = styled('div')`
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
  max-height: calc(100vh - ${({ theme }) => theme.var.headerHeight}px);
  overflow: auto;
`;

export const ProductDetailsWrapper = styled('div')`
  margin: auto;
  flex-grow: 0.5;
`;

export const Text = styled(Typography)`
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const TextFieldWrapper = styled(TextField)<{ width?: number }>`
  width: ${({ width, theme }) => (width ? `${width}px` : `${theme.var.wideInputControl}px`)};
`;

export const SelectWrapper = styled(Select)`
  width: ${({ theme }) => `${theme.var.wideInputControl}px`};
`;

export const IconWrapper = styled(Icon)`
  vertical-align: bottom;
  padding-right: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const Spacing = styled('div')`
  margin: 0;
  padding: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const DividerWrapper = styled(Divider)<{ width?: number }>`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`} 0;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const FooterWrapper = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => `${theme.var.footerHeight}px`};
  padding: ${({ theme }) => `0 ${theme.var.paddingBase * 2}px`};
  bottom: 0;
  left: 0;
  right: 0;
`;

// This component is intended to be a child of a flex container, which will occupy all the available space
export const FlexSpacer = styled('div')`
  flex-grow: 1;
`;

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.var.marginBase}px;
  top: ${({ theme }) => theme.var.marginBase}px;
`;

export const AppLoadingProgress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
