import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const NameAndReleaseWrapper = styled('div')`
  display: flex;
  align-items: baseline;
`;

export const NameTitle = styled(Typography)`
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ProductReleasesDescription = styled(Typography)`
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ProductReleaseContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ProductReleaseTypographyWrapper = styled('div')`
  display: flex;
`;

export const LockTooltip = styled(Tooltip)`
  margin-left: ${({ theme }) => `${theme.var.marginBase / 3}px`};
  padding: 0;
`;
