import { AccProject, ProjectEntitlement } from 'mid-types';
import { mockAccount, mockAccount2 } from './mockAccounts';

export const mockProject: AccProject = {
  accountId: mockAccount.id,
  id: '8e62b463-b77e-4e8d-af0f-f515970debd2',
  name: 'mock project name',
  country: 'US',
  platform: 'acc',
  thumbnailImageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
};

export const mockProject2: AccProject = {
  accountId: mockAccount.id,
  id: '11111111-b77e-4e8d-af0f-f515970debd2',
  name: 'mock project name 2',
  country: 'US',
  platform: 'acc',
  thumbnailImageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
};

export const mockBim360Project: AccProject = {
  accountId: mockAccount2.id,
  id: '22222222-b77e-4e8d-af0f-f515970debd2',
  name: 'mock bim360 project',
  country: 'US',
  platform: 'bim360',
  thumbnailImageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
};

export const mockAccProjectEntitlement: ProjectEntitlement = {
  id: mockProject.id,
  name: mockProject.name,
  accountId: mockAccount.id,
  accountName: mockAccount.name,
  platform: mockProject.platform,
};

export const mockBim360ProjectEntitlement: ProjectEntitlement = {
  id: mockBim360Project.id,
  name: mockBim360Project.name,
  accountId: mockAccount2.id,
  accountName: mockAccount2.name,
  platform: mockBim360Project.platform,
};
