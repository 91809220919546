import { CacheError, EnvError } from 'mid-utils';
import { CachedFileInfo, FileMetaData } from '../interfaces/localCache';
import text from '../mid-addin-lib.text.json';
import browserApiService from '../services/browserApiService';

/**
 * Retrieves the metadata for locally cached files.
 * @param key The key to get the data for.
 * @returns Cached file metadata.
 * @throws If the JSON returned by the host API cannot be parsed.
 */
export const getLocallyCached = async (key: string): Promise<CachedFileInfo[]> => {
  if (!browserApiService.getLocallyCached) {
    throw new EnvError('getLocallyCached is not implemented in host API');
  }

  try {
    const fileMetaData: CachedFileInfo[] = await browserApiService.getLocallyCached(key);
    return fileMetaData;
  } catch {
    throw new CacheError(text.failedToDeserializeFileMetadata);
  }
};

/**
 * Adds file information to the local cache.
 * @param key The key to store the data under.
 * @param signedUrl Signed URL from which to download the file.
 * @param name The filename.
 * @param type The output type, e.g. "RFA".
 * @param metaData Additional meta data (optional).
 */
export const downloadFileToLocalCache = async (
  key: string,
  signedUrl: string,
  name: string,
  type: string,
  metaData?: FileMetaData,
): Promise<void> => {
  if (!browserApiService.downloadFileToLocalCache) {
    throw new EnvError('downloadFileToLocalCache is not implemented in host API');
  }

  if (!metaData) {
    return await browserApiService.downloadFileToLocalCache(key, signedUrl, name, type, null);
  }

  const metaDataJson = JSON.stringify(metaData);

  return await browserApiService.downloadFileToLocalCache(key, signedUrl, name, type, metaDataJson);
};

export const writeToCache = async (key: string, type: string, metaData: FileMetaData): Promise<void> => {
  const metaDataJson = JSON.stringify(metaData);
  return await browserApiService.writeToCache(key, type, metaDataJson);
};

export const writeToPublishData = async (publishData: string): Promise<void> =>
  await browserApiService.writeToPublishData(publishData);
