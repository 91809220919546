import { Environment } from 'mid-types';
import { AuthenticationError, EnvError } from 'mid-utils';
import text from '../mid-addin-lib.text.json';
import { getAuthToken } from './auth';
import browserApiService from '../services/browserApiService';

export const getAuthTokenAndEnv = async (): Promise<{ token: string; env: Environment }> => {
  const token = await getAuthToken();
  if (!token) {
    throw new AuthenticationError(text.unauthorizedAccessMessage);
  }
  const env = await browserApiService.getEnvironment();
  if (!env) {
    throw new EnvError("Couldn't retrieve environment from host application.");
  }

  return { token, env };
};
