import React from 'react';
import { productCustomizationTestIds } from '../../global/dataTestIds';
import { FieldSetContainer } from '../../global/styles/Common.styles';
import MIDEmptyState, { EMPTY_STATE_ILLUSTRATION_TYPES } from '../EmptyState/MIDEmptyState';
import {
  FormLoadingSpinner,
  ProductCustomizationFormWrapper,
  ConfigureProductCustomizationHeader,
} from './ProductCustomization.styles';
import { mapInputsToFormLayoutRules } from './ProductCustomization.utils';
import { ForgeValidationError, isFormLayoutGroup } from 'mid-utils';
import { DCInput } from '@adsk/offsite-dc-sdk';
import { DCInputUIExtension, FormLayoutGroup, FormRules } from 'mid-types';
import Divider from '@mui/material/Divider';
import text from '../../mid-react-common.text.json';
import { ProductCustomizationFormInputs } from './Components/ProductCustomizationFormInputs';
import { ProductCustomizationFormGroup } from './Components/ProductCustomizationFormGroup';

const { productCustomizationForm, productCustomizationFormLoadingSpinner, productCustomizationFormHeader } =
  productCustomizationTestIds;

interface ProductCustomizationFormProps {
  inputs: DCInput[];
  inputsError?: ForgeValidationError;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
  loading?: boolean;
  inactive?: boolean;
  error?: Error;
  errorDescription?: string | JSX.Element;
  formLayoutRules?: FormRules;
  isFormLoading?: boolean;
}

const ProductCustomizationForm: React.FC<ProductCustomizationFormProps> = ({
  inputs,
  inputsError,
  handleInputUpdate,
  setIsFormDataValid,
  inactive,
  error,
  errorDescription,
  formLayoutRules,
  isFormLoading,
}) => {
  if (isFormLoading) {
    return (
      <FieldSetContainer data-testid={productCustomizationForm}>
        <FormLoadingSpinner data-testid={productCustomizationFormLoadingSpinner} />
      </FieldSetContainer>
    );
  }

  if (inputs.length > 0 && error) {
    return (
      <FieldSetContainer data-testid={productCustomizationForm} fullWidth={!!error}>
        <MIDEmptyState
          title={error.message}
          description={errorDescription}
          illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR}
        />
      </FieldSetContainer>
    );
  }

  const inputsAfterRules = mapInputsToFormLayoutRules(inputs, formLayoutRules?.inputs);

  return (
    <>
      {formLayoutRules?.formName && (
        <>
          <ConfigureProductCustomizationHeader variant="h2" data-testid={productCustomizationFormHeader}>
            {formLayoutRules?.formName}
          </ConfigureProductCustomizationHeader>
          <Divider flexItem />
        </>
      )}
      <ProductCustomizationFormWrapper>
        <FieldSetContainer data-testid={productCustomizationForm} fullWidth={!!error}>
          {inputsAfterRules.length <= 0 && (
            <MIDEmptyState title={text.formGroupIsEmpty} warning={text.formMustIncludeOneInput} />
          )}
          {inputsAfterRules.length > 0 &&
            !error &&
            inputsAfterRules.map((input: DCInputUIExtension<DCInput> | FormLayoutGroup, index) => {
              if (isFormLayoutGroup(input)) {
                return (
                  <ProductCustomizationFormGroup
                    formLayoutGroup={input}
                    inputsError={inputsError}
                    inactive={inactive}
                    handleInputUpdate={handleInputUpdate}
                    setIsFormDataValid={setIsFormDataValid}
                    key={input.groupName + index}
                  />
                );
              }
              return (
                <ProductCustomizationFormInputs
                  currentInput={input}
                  inputsError={inputsError}
                  inactive={inactive}
                  handleInputUpdate={handleInputUpdate}
                  setIsFormDataValid={setIsFormDataValid}
                  key={input.name + index}
                />
              );
            })}
        </FieldSetContainer>
      </ProductCustomizationFormWrapper>
    </>
  );
};

export default ProductCustomizationForm;
