import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  newFolderName?: string;
  selectedFolderUrn?: string;
};

export class BIM360FolderCreationError extends CustomError {
  errorCode = ErrorCode.BIM360FolderCreationError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
